import storage from "redux-persist/es/storage";
import thunk from 'redux-thunk';
import { createBrowserHistory }from 'history';
import {persistCombineReducers, persistStore} from "redux-persist";
import { connectRouter, routerMiddleware } from 'connected-react-router'
import {createStore, applyMiddleware} from 'redux';
import rootReducer from './rootReducer'

const loggerMiddleWare = store => next => action => {
    console.log("[LOG] Action triggered", action);
    next(action);
};

/**
 * cardType: 1 for ID/Passport, 2 for Medicard, 3 for National ID
 */
const initialState = {
    config: {
        instanceID: null,
        frontSubmitted: false,
        backSubmitted: false,
        showHeader: false,
        uuidValid: null,
        limitReached: false,
        acuantInitialized: false,
        cropSettings: {}
    },
    submissionState: {
        details: {
            new: 0
        },
        screens: {},
        submissionState: {},
        submissionAttempts: 1,
    },
    processedData: {
        faceMatch: null,
        result: null
    },
    idProperties: {
        cardType: 0,
        orientation: 0,
        sidesLeft: 2
    },
    eVerification: {
        enabled: false,
        customFields: [],
        countries: [],
        activatedCountries: [],
        selectedCountry: {
            name: "",
            iso_3166_3: "",
            iso_3166_2: "",
            supports_address_lookup: false
        },
        firstName: null,
        lastName: null,
        dob: null,
        address1: "",
        address2: "",
        city: "",
        postCode: "",
        phone: null,
        phonePrefix: null,
        phoneRaw: null,
        customField: null,
        errors: {},
    },
    addressLookup: {
        hidden: false,
        fullResult: false,
        active: false
    },
    spinner: {
        pendingItems: 0
    }
};

function isFireFox(){
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
}

if (isFireFox()) {
    (function(history) {
        var pushState = history.pushState;
        history.pushState = function(state) {
        };
    })(window.history);   
}

export const history = createBrowserHistory({basename: process.env.REACT_APP_BASENAME});

const config = {
    key: 'idscango',
    storage,
    blacklist: ['config', 'processedData', 'idProperties']
};

const reducer = persistCombineReducers(config, rootReducer);

function configureStore() {
    let store = createStore(
        connectRouter(history)(reducer),
        initialState,
        applyMiddleware(thunk, loggerMiddleWare, routerMiddleware(history))
    );
    let persistor = persistStore(store);
    return {persistor, store};
}

export const {persistor, store} = configureStore();