import React, {Component} from 'react';
import '@babel/polyfill';
import {Switch, Route} from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router'
import {PersistGate} from 'redux-persist/es/integration/react';
import {connect, Provider} from 'react-redux';
import CapturePhoto from './screens/CapturePhoto';
import AppEntry from './screens/AppEntry';
import StartScreen from './screens/StartScreen';
import DocumentSelection from './screens/DocumentSelection';
import EVerification from './screens/EVerification';
import CaptureSelfie from './screens/CaptureSelfie';
import Results from './screens/Results/index';
import Success from './screens/Success'
import MobileHandoff from './screens/MobileHandoff';
import Instructions from './screens/Instructions';
import Error from './screens/Error/index';
import "./styles/main.css";
import ProcessedImageResult from "./screens/ProcessedImageResult";
import AcuantReactCamera from "./screens/AcuantReactCamera";
import i18next from 'i18next';
import { acuantInitialized } from "./screens/actions/configActions.js";
import { awaitResponse, responseReceived } from "./screens/actions/spinnerActions.js";
import ApiService from "./services/api/api";
import { bindActionCreators } from "redux";

import { environment } from './config/environment';
/*
global Raven
 */



function storeUserId( queryParams ) {
    /*
        Replaced the logic localstorage with the sessionStorage, because the localstorage values are changed 
        in all tabs for the browser, the sessionStorage value changed only the current tab of the browser
    */
    sessionStorage.removeItem("sessionid"); // always generate a new session onload
    sessionStorage.removeItem("submissionid"); // always get new submission onload
    sessionStorage.removeItem("target");
    decodeURI( queryParams ).replace('?', '').split('&')
    .map( param => param.split('=') ).reduce((values, [ key, value ]) => {
        if( key !== undefined  || value !== undefined ) {
                  
            sessionStorage.removeItem("key");
            sessionStorage.setItem(key, value);
        }
    }, {})        
}
 
 
class App extends Component {

    constructor(props){
        // parse the data from the iframe query string and save to local storage for all
        // api requests. please note that when on the success page you click finish and the session is
        // deleted and then regenerated again as the app is forced to reload
        // device_type header also set here
        // /Applications/MAMP/htdocs/Apps/id-pal-kyc-app/frontend/src/screens/actions/configActions.js
        storeUserId( window.location.search ) 
        super(props);
        this.state = {
            isAcuantSdkLoaded: false
        }
        this.isInitialized = false;
        this.isIntializing = false;
    }

    componentDidMount() {
        if (process.env.REACT_APP_SENTRY_SUBSCRIPTION_ID && process.env.REACT_APP_SENTRY_SUBSCRIPTION_ID.length > 0) {
            Raven.config(process.env.REACT_APP_SENTRY_SUBSCRIPTION_ID).install()
        }
        const lang = sessionStorage.getItem("lang");
        i18next.changeLanguage(lang);


            // if (isMobile == true) {
            //     this.props.routerHistory.replace('/error/mobileonly');
            //     // document.body.classList.add('mobile-only');
            // } else {
                if (!this.props.config) {
                    this.props.routerHistory.replace('/');
                }
            // }        
        
        // if (process.env.REACT_APP_MOBILE_ONLY === 'true') {
        //     if (!isMobile) {
        //         this.props.routerHistory.replace('/error/mobileonly');
        //         document.body.classList.add('mobile-only');
        //     } else {
        //         if (!this.props.config) {
        //             this.props.routerHistory.replace('/');
        //         }
        //     }
        // } else {
            // if (!this.props.config) {
            //     this.props.routerHistory.replace('/');
            // }
        // }

        // TODO: Add a method that sets up and initialises our "idpal" db and creates a "captures" object

        const sdk = document.createElement("script");
        sdk.src = "AcuantJavascriptWebSdk.min.js?v=20211027";
        sdk.async = true;

      
        document.body.appendChild(sdk);
    }

    componentDidCatch(error, errorInfo) {
        if (process.env.REACT_APP_SENTRY_SUBSCRIPTION_ID && process.env.REACT_APP_SENTRY_SUBSCRIPTION_ID.length > 0) {
            Raven.captureException(error, {extra: errorInfo});
        }
        this.props.routerHistory.push('/error/default')
    }

    initialize() {
        if(!this.isInitialized && !this.isIntializing){
            this.isIntializing = true;

           this.props.awaitResponse();

            ApiService.getToken().then(response => {
                if(response.hasOwnProperty('data')){
                    window.AcuantJavascriptWebSdk.initializeWithToken(
                        response.data.token,
                        process.env.REACT_APP_ACAS_ENDPOINT,
                        {
                            onSuccess:function(){
                                this.props.responseReceived();
                                this.props.acuantInitialized(true);
                                this.isInitialized = true;
                                this.isIntializing = false;
                                this.setState({
                                    isAcuantSdkLoaded:true
                                })
                            }.bind(this),

                            onFail: function(){
                                this.props.responseReceived();
                                this.props.acuantInitialized(false);
                                this.isIntializing = false;
                                this.setState({
                                    isAcuantSdkLoaded: false
                                })
                            }.bind(this)
                        }
                    );
                } else {
                    this.props.responseReceived();
                    this.isIntializing = false;
                    this.props.acuantInitialized(false);
                }
            })

        } 
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Initialize once we have a valid Session
        if (prevProps.instanceID !== this.props.instanceID) {
            if (this.props.instanceID) {
                this.initialize();
            }
        }
    }

    render() {

        const uuid = sessionStorage.getItem("uuid");
        const brandTheme = sessionStorage.getItem("brand") ? sessionStorage.getItem("brand") : "id-pal";
        // const lang = window.appData.lang;  
        // const uuid = window.appData.uuid;
        // if(!uuid || !lang) {
        //     alert(`Problem loading page, no uuid/lang. Please load from within an iframe`)
        // }


        return (
            <div className={'mainContent brand-' + brandTheme }>
                {
                <Provider store={this.props.store}>
                    <PersistGate loading={null} persistor={this.props.persistor}>
                        <ConnectedRouter history={this.props.routerHistory}>
                            <Switch>
                                <Route path="/" exact component={AppEntry}/>                    
                                <Route path="/start" exact component={StartScreen}/>
                                <Route path="/select" exact component={DocumentSelection}/>
                                <Route path="/instruction/:type/:side" exact component={Instructions}/>
                                <Route path="/capture/photo/:uuid?/:device_type?" exact component={CapturePhoto}/>
                                <Route path="/capture/camera/" exact component={AcuantReactCamera}/>
                                <Route path="/photo/confirm/:uuid?/:device_type?" exact component={ProcessedImageResult} />
                                <Route path="/capture/selfie/:uuid?/:device_type?" exact component={CaptureSelfie}/>
                                <Route path='/results/:uuid?/:device_type?' component={Results}/>
                                <Route path='/success/:uuid?/:device_type?' component={Success}/>
                                <Route path="/e_verification" exact component={EVerification}/>
                                <Route path="/mobile_handoff/" exact component={MobileHandoff}/>
                                <Route path="/error" component={Error}/>
                            </Switch>
                        </ConnectedRouter>
                    </PersistGate>
                </Provider>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        acuantInitialized,
        awaitResponse,
        responseReceived
    }, dispatch);
}


export default(connect(mapStateToProps, mapDispatchToProps)(App) );