import React, { Component, Fragment } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Header from "./Header";
import { setInstanceID } from "./actions/configActions";
import { withNamespaces } from 'react-i18next';
import Navigator from "./Navigator";
import i18n from './../i18n';

// Images
import loadingGif from '../assets/gifs/loading.gif';

const languages = [
    { label: "Français", value: "fr" },    
    { label: "English", value: "en" },
    { label: "España", value: "es" }
];

 
class AppEntry extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputValue: null,
            uuidExists: false,
            brandingExists: null,
            languageCode: i18n.language,
            loadingError: null
        }

        this.switchLanguage = this.switchLanguage.bind(this);
    }
    
    componentDidMount() {
        sessionStorage.removeItem('documentRegion');
        const uuid = sessionStorage.getItem("uuid");

        if(uuid !== null && uuid.length) {
            this.setState({
                uuidExists: true
            });
        }

        if (!this.props.instanceID) {
            if(!this.state.uuidExists) {
                this.props.setInstanceID();
            }
        }

        this.getErrorMessage();
        
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            brandingExists: nextProps.branding
        });
    }   

    switchLanguage(event) {
        i18n.changeLanguage( event.target.value );
        
        this.setState({languageCode: event.target.value});
    }

    getErrorMessage() {
        let message = null;
        if ( !this.props.uuidValid || !sessionStorage.getItem("uuid").length ) {
            message = 'idpal_uuid_invalid_error'
        } else if ( this.props.acuantInitialized === false ) {
            message = 'idpal_initialization_error'
        } else if ( this.props.limitReached ) {
            message = 'idpal_submission_limit_error'
        }
        this.setState({loadingError: message});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.uuidValid !== this.props.uuidValid
            || prevProps.acuantInitialized !== this.props.acuantInitialized
            || prevProps.limitReached !== this.props.limitReached
        ) {
            this.getErrorMessage();
        }
    }

    
    render() {
        const { t } = this.props;

        // Applied loading screen, before getting branding details
        if ( this.props.pendingItems > 0 ) {
            return (<div className='u-text-center start-loading'><img alt={'idscango'} src={ loadingGif } className='capture' /></div>);
        }

        return (

            <Fragment>

                <Header />
      
                <div className='o-site-wrap'>

                    { this.state.loadingError &&
                        <div className="u-generic-text  u-text-center">
                            <div className="alert-no-uuid">
                                <p> {t(this.state.loadingError)} </p>
                            </div>
                            <div className='u-generic-text  u-text-center languageSelection'>

                                <label>Language:</label>
                                <select value={this.state.languageCode} onChange={this.switchLanguage}>
                                    {languages.map((lang) =>
                                        <option value={lang.value} key={lang.value}>{lang.label}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    }
                    { !this.state.loadingError && this.state.brandingExists &&
                        <Navigator page={"app_entry"} action={"next"} />
                    }

                </div>

            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        acuantInitialized: state.config.acuantInitialized,
        branding: state.config.profile,
        uuidValid: state.config.uuidValid,
        limitReached: state.config.limitReached,
        pendingItems: state.spinner.pendingItems,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setInstanceID
    }, dispatch);
}

export default withNamespaces('translation')(connect(mapStateToProps, mapDispatchToProps)(AppEntry) );