import React, { Component, Fragment } from 'react';
import Webcam from "react-webcam";
import Header from './Header';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { processID } from "./actions/processDataActions";
import Processing from "./Processing";
import apiService from '../services/api/api';
import { withNamespaces } from 'react-i18next';
import CustomButton from './Button';

// Images
import livenessSvg from '../assets/img/liveness.svg';
import loadingGif from '../assets/gifs/loading.gif';
import Navigator from "./Navigator";

class CaptureSelfie extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            inputValue: '',
            selfie: null,
            isVideoStreamSupported: false,
            startVideoSteamCapture: false,
            screenshot: null,
            videoConstraints: {
                facingMode: "user",
                width: window.innerHeight, // Note that width and height need to be inverted on mobile
                height: window.innerWidth
            },
            navigation: {
                action: 'load',
                props: null
            }
        };
    }

    componentDidMount() {
        // Check if HTML5 Media API is supported
        if(window.AcuantCamera.isCameraSupported) {
            this.setState({
                isVideoStreamSupported: true
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.resultData !== this.props.resultData) {
            this.processSelfieAndRedirect();
        }
    }

    openFrontCamera() {
        // NOTE: Commented out video stream capture to improve quality until we have a better solution
        /*
        if(this.state.isVideoStreamSupported) {
            this.setState({
                startVideoSteamCapture: true
            });
        }
        else {
        */
            window.AcuantPassiveLiveness.startSelfieCapture(this.onCaptured.bind(this));
        /*
        }
        */
    }

    captureScreenshotFromVideo() {
        const screenshot = this.webcam.getScreenshot();
        // this.setState({ screenshot });
        let image = screenshot.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
        this.onCaptured(image);
    }

    onCaptured(image) {
        this.setState({loading: true});

        // Send passive liveness selfie image to the server for processing
        apiService.getPassiveLivenessResult(
            image
        ).then(response => {
            // if(response.success && response.success === true) {
                this.props.dispatch({ payload: response, type: '@@acuant/ADD_FACE_LIVENESS_DATA' });
                this.setState({
                    navigation: {
                        action: 'next'
                    }
                })
            // }
            // else {
            //     this.openFrontCamera();
            //     this.setState({
            //         loading: false,
            //         errorMessage: "There was an issue uploading your selfie. Please retake again"
            //     });

                
            // }

            // TODO: Add opportunity for retry and track the recapture count
        }).catch(error => {
            // Handle error
            alert('error uploading liveness image')
        });
    }

    render() {
        const { t } = this.props;

        return (
            <Fragment>

                <Header />

                {this.state.loading &&
                    <div className='o-site-wrap'>

                        <div className='u-generic-text  u-text-center u-btm-buffer'>
                            <span>{t('idpal_analyzing')}</span>
                        </div>

                        <div className='u-display-loading u-text-center'>
                            <img alt={'idscango'} src={ loadingGif } className='capture' />
                        </div>

                    </div>
                }

                {this.state.startVideoSteamCapture &&
                    <div className='body capture_selfie'>
                        <Webcam
                            audio={false}
                            ref={node => this.webcam = node}
                            screenshotQuality={1}
                            mirrored={true}
                            imageSmoothing={false}
                            screenshotFormat="image/jpeg"
                            videoConstraints={this.state.videoConstraints}
                        />
                        <div className={'button_container'}>
                            <CustomButton className='btn' label = 'Capture photo' handleClick = {this.captureScreenshotFromVideo.bind(this)} />
                        </div>
                        {this.state.screenshot && (
                            <img
                            src={this.state.screenshot}
                            />
                        )}
                    </div>
                }

                {!this.state.startVideoSteamCapture && !this.state.loading &&
                    <div className='o-site-wrap instructions'>

                        <div className='u-generic-text  u-text-center'>
                            {t('idpal_passive_liveness_instruction')}
                        </div>

                        <div className='u-display-selfie u-text-center'>
                            <img alt='idscango' className='capture' src={ livenessSvg } />
                        </div>

                        <p className='u-generic-text  u-text-center'>
                            {t('idpal_passive_liveness_instruction_continued')}
                        </p>

                        <div className="u-generic-text  u-text-center">
                            <CustomButton className='btn' label = {t('idpal_open_camera')} handleClick = {this.openFrontCamera.bind(this)} />
                        </div>

                    </div>
                }

                <Navigator page={'passive_liveness'} action={this.state.navigation.action} propsToPass={this.state.navigation.props} />
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        faceMatch: state.processedData.faceMatch,
        resultData: state.processedData.result,
        liveness: state.processedData.liveness
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ processID }, dispatch);
    return { ...actions, dispatch };
}

export default withNamespaces('translation')(connect(mapStateToProps, mapDispatchToProps)(CaptureSelfie) );