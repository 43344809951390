export function configReducer(state = {}, action) {
    switch (action.type) {
        case '@@acuant/SET_INSTANCE_ID':
            return {
                ...state,
                instanceID: action.data
            };
        case '@@acuant/FRONT_ID_SUBMITTED':
            return {
                ...state,
                frontSubmitted: true
            }
        case '@@acuant/BACK_ID_SUBMITTED':
            return {
                ...state,
                backSubmitted: true
            }
        case '@@acuant/RESET_CONFIG':
            return {
                ...state,
                instanceID: null,
                frontSubmitted: false,
                backSubmitted: false
            }
        case '@@acuant/SET_BRANDING':
            return {
                ...state,
                profile: action
            }
        case 'SET_UUID_VALIDITY':
            return {
                ...state,
                uuidValid: action.data
            }
        case 'LIMIT_REACHED':
            return {
                ...state,
                limitReached: true
            }
        case 'SHOW_HEADER':
            return {
                ...state,
                showHeader: action.data
            }
        case 'ACUANT_INITIALIZED':
            return {
                ...state,
                acuantInitialized: action.data
            }
        case 'SET_CROP_SETTINGS':
            return {
                ...state,
                cropSettings: action.data[action.device]
            }
        default:
            return state;
    }
}