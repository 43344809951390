import {eVerification} from "../../config/eVerification";

export function submissionStateReducer(state = {}, action) {
    switch (action.type) {
        case 'SET_SUBMISSION_STATE':
            return {
                ...state,
                details: action.data.details,
                screens: action.data.screens,
                submissionState: action.data.state
            }
        case 'SET_RETRY_LIMIT':
            return {
                ...state,
                submissionAttempts: action.data
            }
        case 'USE_RETRY':
            return {
                ...state,
                submissionAttempts: state.submissionAttempts - 1
            }
        case 'SET_E_VERIFICATION_RESULT': {
            let updatedState = state.submissionState;
            updatedState.e_verification.result = action.data;
            updatedState.e_verification.completed = 1;
            return {
                ...state,
                submissionState: updatedState
            }
        }
        case 'SUBMISSION_STARTED':
            let updatedDetails = state.details;
            updatedDetails.new = 0;
            return {
                ...state,
                details: updatedDetails
            }
        default:
            return state;
    }
}