import { history } from "../../store";
import {isMobile, isTablet} from "react-device-detect";
import apiService from "../../services/api/api";


// Navigate to the next page based on page and state
export function navigateNext(page, state, props) {
    let next_page = "/";
    let finalize = false;
    switch (page) {
        case 'app_entry' :
            next_page = nextFromAppEntry(state) ;
            finalize = true;
            break;

        case 'start_screen' :
            next_page = nextFromStart(state);
            finalize = true;
            break;

        case 'e_verification' :
            next_page = nextFromEVerification(state);
            break;

        case 'document_selection' :
            next_page = nextFromDocumentSelection(state, props);
            break;

        case 'instructions' :
            next_page = nextFromInstructions(props);
            break;

        case 'capture_photo' :
            next_page = nextFromCapturePhoto();
            break;

        case 'document_capture' :
            next_page = nextFromDocumentCapture(props);
            break;

        case 'document_review' :
            next_page = nextFromDocumentReview(state, props);
            finalize = true;
            break;

        case 'passive_liveness' :
            next_page = nextFromPassiveLiveness();
            finalize = true;
            break;
    }

    if (next_page === '/success' && finalize === true) {
        finalizeThenSuccess();
    } else {
        history.push(next_page, props);
    }

    return (dispatch) => {
        dispatch({type : "NAVIGATED"})
    }
}

// Check status on load
export function checkLoadState(page, state) {
    switch (page) {
        case 'e_verification' :
            eVerificationLoadState(state);
            break;
    }
}

// Go back
export function goBack() {
    history.goBack();
}


export function showError() {
    history.push( '/error/default');
}

// Finalize then Success Page
export function finalizeThenSuccess() {
    apiService.postFinalizeSubmission().then(response => {
        if (response.code !== 200) {
            showError();
        } else {
            history.push('/success')
        }
    })
}

// Next Page
export function nextFromAppEntry(state) {
    if (state.screens.start_screen) {
        return '/start'
    } else if (state.screens.e_verification && !state.submissionState.e_verification.completed ) {
        return '/e_verification';
    } else if (state.screens.mobile_handoff && !isMobile && !isTablet) {
        return '/mobile_handoff';
    } else if (state.screens.document_upload && !state.submissionState.document_upload.completed ) {
        return '/select';
    } else if (state.screens.passive_liveness && !state.submissionState.passive_liveness.completed && (isMobile || isTablet) ) {
        return '/capture/selfie';
    } else {
        return '/success';
    }
}

// Next Page
export function nextFromStart(state) {
    if (state.screens.e_verification && !state.submissionState.e_verification.completed ) {
        return '/e_verification';
    } else if (state.screens.mobile_handoff && !isMobile && !isTablet) {
        return '/mobile_handoff';
    } else if (state.screens.document_upload && !state.submissionState.document_upload.completed ) {
        return '/select';
    } else if (state.screens.passive_liveness && !state.submissionState.passive_liveness.completed && (isMobile || isTablet) ) {
        return '/capture/selfie';
    } else {
        return '/success';
    }
}

export function nextFromEVerification(state) {
    if (state.submissionState.e_verification.result === 'pass') {
        return '/success';
    } else if (state.screens.mobile_handoff && !isMobile && !isTablet) {
        return '/mobile_handoff';
    } else if (state.screens.document_upload) {
        return '/select';
    }
    return '/e_verification';
}

export function nextFromDocumentSelection(state, props) {
    if (!isMobile && !isTablet) {
        return '/capture/camera';
    } else {
        return '/instruction/' + props.documentId + '/1';
    }
}

export function nextFromInstructions(props) {
    if (props.isRetry) {
        return '/capture/photo';
    } else {
        return '/capture/camera';
    }
}

export function nextFromDocumentCapture(props) {
    if (props.isRetry) {
        return '/capture/photo';
    } else {
        return '/photo/confirm';
    }
}

export function nextFromCapturePhoto() {
    return '/capture/camera';
}

export function nextFromDocumentReview(state, props) {
    if (state.submissionAttempts === 0) {
        if (state.screens.passive_liveness && isMobile) {
            return '/capture/selfie';
        } else {
            return '/success';
        }
    }

    if (props.sidesLeft === 1) {
        if (isMobile || isTablet) {
            return '/instruction/' + props.documentId + '/2';
        } else {
            return '/capture/photo';
        }
    }

    if (props.isRetry) {
        if (isMobile || isTablet) {
            return '/instruction/' + props.documentId + '/1';
        } else {
            return '/capture/photo';
        }
    }
}

export function nextFromPassiveLiveness() {
    return '/success';
}

// Load Checks
export function eVerificationLoadState(state) {
    if (state.submissionState.e_verification.completed === 1) {
        navigateNext('e_verification', state)
    }
}