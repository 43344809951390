import React, { Component, Fragment } from 'react';
import { bindActionCreators } from "redux";
import { sendSmsLink } from "./actions/mobileHandoffActions";
import { connect } from "react-redux";
import Header from "./Header";
import { withNamespaces } from 'react-i18next';
import CustomButton from './Button';
import loadingGif from '../assets/gifs/loading.gif';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import Label from "./Inputs/Label";
import i18next from "i18next";
import { phone } from "phone";



class MobileHandoff extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phone: null,
            linkSent: false,
            color: '',
            error: null,
            uuid: '',
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.sendAgain = this.sendAgain.bind(this)
    }

    componentDidMount() {
        this.setState({
            color: this.props.branding.data.company_branding.primary_color ?
                this.props.branding.data.company_branding.primary_color : this.state.color,
            uuid: sessionStorage.getItem('uuid'),
            phone: this.props.phone
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            brandingExists: nextProps.branding
        });
    }

    validate() {
        let error = null;
        if (!phone("+" + this.state.phone).isValid) {
            error = i18next.t('idpal_please_enter_valid_number');
        }
        return error;
    }

    sendAgain() {
        // Put back uuid in session storage to make request again
        sessionStorage.setItem('uuid', this.state.uuid);
        this.setState((state, props) => {
            return {linkSent: false};
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        let error = this.validate();

        this.setState((state, props) => {
            return {error: error};
        })

        if (!error) {
            let data = {
                phone: this.state.phone,
                uuid: this.state.uuid,
            }
            this.props.sendSmsLink(data);
            this.setState((state, props) => {
                return {linkSent: true};
            });
            // Remove UUID so submission can't be continued on this browser
            sessionStorage.removeItem('uuid');
        }
    }


    render() {
        const { t } = this.props;

        const textColor = this.state.color ? {
            color : '#' + this.state.color
        } : {};


        if ( this.state.linkSent === false ) {
            return (

                <Fragment>
                    <Header/>

                    <div className='o-site-wrap handoff'>
                        <h1 className={"u-text-center"}>
                            {t('idpal_verify_identity_on_mobile_device')}
                        </h1>
                        <h4 className={"u-text-center"}>
                            {t('idpal_send_link_to_phone')}
                        </h4>


                        <form onSubmit={this.handleSubmit} className={'phone'}>

                            <div className={this.state.error ? 'error input-group' : 'input-group'}>
                                <Label id={'phone'} required={false} label={t('idpal_phone')} />
                                <PhoneInput
                                    enableSearch
                                    disableSearchIcon
                                    inputClass={"phone-input"}
                                    placeholder={''}
                                    value={this.props.phone}
                                    onChange={phone => this.setState({phone})}
                                />

                                {this.state.error &&
                                    <span className="error-message">{this.state.error}</span>
                                }
                            </div>

                            <CustomButton className='btn' label={t('idpal_send')}/>

                        </form>

                    </div>

                </Fragment>
            );
        }
        if (this.state.linkSent === true ){

            return (
                <Fragment>
                    <Header/>

                    <div className='o-site-wrap handoff'>
                        <h1 className={"u-text-center"}>
                            {t('idpal_check_sms')}
                        </h1>
                        <h4 className={"u-text-center"}>
                            {t('idpal_didnt_receive_link')}
                            <a style={textColor} onClick={ this.sendAgain }>{t('idpal_send_again')}</a>
                        </h4>
                    </div>

                </Fragment>
            )
        }
    }
}



function mapStateToProps(state) {
    return {
        branding: state.config.profile,
        uuidValid: state.config.uuidValid,
        limitReached: state.config.limitReached,
        pendingItems: state.spinner.pendingItems,
        phone: state.eVerification.phone
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        sendSmsLink
    }, dispatch);
}


export default withNamespaces('translation')(connect(mapStateToProps, mapDispatchToProps)(MobileHandoff) );