import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { setInstanceID } from "./actions/configActions";
import { connect } from "react-redux";

class CustomButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            color: '',
            hover: false
        }
    }

    componentDidMount() {
        this.setState({
            color: this.props.branding.data.company_branding.primary_color ? 
            this.props.branding.data.company_branding.primary_color : this.state.color
        });
    }

    handleMouseEnter = () => {
        this.setState({
            hover: true
        });
    }
    handleMouseLeave = () => {
        this.setState({
            hover: false
        });
    }

    render() {
        let type = this.props.type;
        let hover = this.state.hover;

        const defaultPrimaryColorStyle = this.state.color ? {
           color: '#ffffff',
           backgroundColor: '#' + this.state.color,
           boxShadow: 'inset 0px 0px 0px 2px ' + '#' + this.state.color
        } : {};

        const hoverStyle = {
            color: '#' + this.state.color,
            backgroundColor: '#ffffff',
            transition: 'all 300ms ease-in-out',
            boxShadow: 'inset 0px 0px 0px 2px ' + '#' + this.state.color
        }

        return (
            <button type={this.props.type} id={this.props.id}
                style={type ? (hover ? defaultPrimaryColorStyle : hoverStyle) : defaultPrimaryColorStyle}
                className={this.props.className}
                onClick={this.props.handleClick}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}>{this.props.label}
            </button>
        );
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        branding: state.config.profile
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setInstanceID }, dispatch);
}

export default (connect(mapStateToProps, mapDispatchToProps)(CustomButton));
