import React, { Component, Fragment } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Header from "./Header";
import { setCardType } from "./actions/idPropertiesActions";
import { setInstanceID } from "./actions/configActions";
import { withNamespaces } from 'react-i18next';
import CustomButton from './Button';
import i18n from './../i18n';
import Navigator from "./Navigator";

const languages = [
    { label: "Français", value: "fr" },    
    { label: "English", value: "en" },
    { label: "España", value: "es" }
];

 
class DocumentSelection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            languageCode: i18n.language,
            navigation: {
                action: "load",
                props: null
            }
        }

        this.switchLanguage = this.switchLanguage.bind(this);
    }
    
    componentDidMount() {
    }

    openCamera(type) {
        this.props.setCardType(type);
        this.navigateCamera(type);
    }

    navigateCamera(documentId) {
        this.setState({
            navigation: {
                action: "next",
                props: {
                    documentId: documentId
                }
            }
        });
    }
      
    switchLanguage(event) {
        i18n.changeLanguage( event.target.value );
        
        this.setState({languageCode: event.target.value});
    }

    render() {
        const { t } = this.props;

        return (

            <Fragment>

                <Header />
      
                <div className='o-site-wrap'>

                    <div className="o-entryBtns">
                            <div className='u-generic-text  u-text-center u-btm-buffer'>
                                {t('xxx_entry_sentence')}
                            </div>

                            {process.env.REACT_APP_IDPASSPORT_ENABLED === 'true' &&
                            <CustomButton id="passport-button" className='btn btn__arrow' label = {t('idpal_passport')} handleClick = {() => this.openCamera(1)} />
                            }

                            {process.env.REACT_APP_IDPASSPORT_ENABLED === 'true' &&
                            <CustomButton id="national-id-button" className='btn btn__arrow' label = {t('idpal_national_id')} handleClick = {() => this.openCamera(3)} />
                            }

                            {process.env.REACT_APP_IDPASSPORT_ENABLED === 'true' &&
                            <CustomButton id="driving-licence-button" className='btn btn__arrow' label = {t('idpal_driving_licence')} handleClick = {() => this.openCamera(31)} />
                            }
                            {process.env.REACT_APP_IDPASSPORT_ENABLED === 'true' &&
                            <CustomButton id="resident-card-button" className='btn btn__arrow' label = {t('idpal_resident_card')} handleClick = {() => this.openCamera(32)} />
                            }
                            {process.env.REACT_APP_MEDICAL_CARD_ENABLED === 'true' &&
                            <CustomButton className='btn btn__arrow' label = 'Capture Medical Card' handleClick = {() => this.openCamera(2)} />
                            }
                    </div>


                    { !this.props.screens.start_screen &&
                    <div className='u-generic-text  u-text-center languageSelection'>

                        <label>Language:</label>
                        <select value={this.state.languageCode} onChange={this.switchLanguage}>
                            {languages.map((lang) =>
                                <option value={lang.value} key={lang.value}>{lang.label}</option>
                            )}
                        </select>

                    </div>
                    }

                </div>

                <Navigator page={"document_selection"} action={this.state.navigation.action} propsToPass={this.state.navigation.props}/>

            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        acuantInitialized: state.config.acuantInitialized,
        cardType: state.idProperties.cardType,
        branding: state.config.profile,
        uuidValid: state.config.uuidValid,
        limitReached: state.config.limitReached,
        pendingItems: state.spinner.pendingItems,
        screens: state.submissionState.screens
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setCardType, setInstanceID }, dispatch);
}

export default withNamespaces('translation')(connect(mapStateToProps, mapDispatchToProps)(DocumentSelection) );