import React, {Component, Fragment} from 'react';
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next'; 


function DesktopL10n() {
    return <NamespacesConsumer>{(t, { i18n }) => <span>{t('xxxx_desktoponly')}</span>}</NamespacesConsumer>;
  }

export default class DesktopOnly extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <Fragment>
                
                <div className='body column'>

                    <div className='column wrapper description_container desktop_error'>
                        <p className={'description'}>
                        { DesktopL10n()  } 
                        </p>
                    </div>

                </div>
                
            </Fragment>
        )
    }

}