import React, { Component, Fragment } from 'react';
import { bindActionCreators } from "redux";
import { navigateNext, checkLoadState, goBack, showError } from './actions/navigatorActions';
import { connect } from "react-redux";
import { withNamespaces } from 'react-i18next';


class Navigator extends Component {

    constructor(props) {
        super(props);
    }
    
    componentDidMount() {
        if (this.props.action === "next") {
            navigateNext(this.props.page, this.props.submissionState, this.props.propsToPass);
        } else if (this.props.action === "load") {
            checkLoadState(this.props.page, this.props.submissionState)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.action !== this.props.action
        ) {
            if (this.props.action === "next") {
                navigateNext(this.props.page, this.props.submissionState, this.props.propsToPass);
            } else if (this.props.action === "back") {
                goBack();
            } else if (this.props.action === "error") {
                showError();
            }
        }
    }

    render() {
        return (
            <Fragment></Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        submissionState: state.submissionState,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        navigateNext
    }, dispatch);
}

export default withNamespaces('translation')(connect(mapStateToProps, mapDispatchToProps)(Navigator) );