import React, { Component, Fragment } from 'react';
import Header from "./Header";
import {connect} from "react-redux";
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next';
import apiService from '../services/api/api';
import Processing from "./Processing";
import {setCaptured} from "./actions/capturedActions";
import {bindActionCreators} from "redux";
import { isMobile } from "react-device-detect";
import CustomButton from './Button';

// Images
import uploadingPng from '../assets/images/uploading.png';
import Navigator from "./Navigator";

class AcuantReactCamera extends Component {
    constructor(props){
        super(props);
        this.detectedCount = 0;
        this.state = {
            processing: false,
            navigation: {
                action: 'load',
                props: null
            }
        }
    }

    componentDidMount() {
        if (this.props.submissionAttempts > 0) {
            this.startCamera();
        }
    }

    componentWillUnmount(){
      window.AcuantCameraUI.end();
    }

    setProcessing(value) {
        this.setState({ processing: value });
    }

    onCaptured(response) {
        this.setProcessing(true);
    }

    onCropped(response) {
        this.setProcessing(false);

        if (response) {
            this.props.setCaptured(response);
            let imageType = this.getImageType();

            let properties = {
                imageSize: response.image.size,
                width: response.image.width,
                height: response.image.height,
                originalWidth: response.image.originalWidth,
                originalHeight: response.image.originalHeight,
                properties: {
                    sharpness: response.sharpness,
                    glare: response.glare,
                    dpi: response.dpi,
                    documentId: this.props.location.state.documentId,
                    isPassport: response.isPassport,
                }
            }

            // Pass minimum amount of Images
            if (imageType === 'cropped') {
                properties.croppedImage = response.image.data;
            } else if (imageType === 'uncropped') {
                properties.uncroppedImage = response.image.uncroppedData;
                properties.downscaledImage = response.image.downscaledData;
            } else {
                properties.croppedImage = response.image.data;
                properties.uncroppedImage = response.image.uncroppedData;
                properties.downscaledImage = response.image.downscaledData;
            }

            this.setState({
                navigation: {
                    action: "next",
                    props: properties
                }
            })
        } else {
            this.startCamera()
        }
    }

    onFrameAvailable(response) {}

    startCamera() {
        let imageType = this.getImageType();

        if (window.AcuantCameraUI) {
            // if (window.AcuantCamera.isCameraSupported) {
            //     window.AcuantCameraUI.start({
            //         onCaptured: this.onCaptured.bind(this),
            //         onCropped: this.onCropped.bind(this),
            //         onFrameAvailable: this.onFrameAvailable.bind(this)
            //     }, this.onError.bind(this));
            // }
            // else {
                window.AcuantCamera.startManualCapture({
                    onCaptured: this.onCaptured.bind(this),
                    onCropped: this.onCropped.bind(this)
                }, imageType, this.onError.bind(this));
            // }
        }
    }

    getImageType() {
        if (this.props.cropSettings.passport !== this.props.cropSettings.id_card) {
            return "both"
        } else if (this.props.cropSettings.passport === 1) {
            return "cropped"
        } else {
            return "uncropped"
        }
    }

    onError(err){
        window.AcuantCamera.isCameraSupported = false
        alert("This device does not support Live Capture. Manual Capture will be started. Please try again.")
    }

    goBack() {
        this.setState({
            navigation: {
                action: "back",
            }
        })
    }

    retryPhoto() {
        this.setState({
            navigation: {
                action: "next",
                props : {
                    isRetry: true,
                    documentId: this.props.location.state.documentId
                }
            }
        })
    }

    render() {

      const { t } = this.props;

      return(
        <Fragment>

            <Header />

            {!this.state.processing && isMobile &&
                <div className="back-button-container">
                    <button className={'button-with-icon'} onClick={() => this.goBack()}>
                        <span className={'button-icon chevron-left'}>
                            <svg viewBox="0 0 11 18" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.25501 8.99972L10.7832 2.42617C11.0744 2.13215 11.0721 1.66186 10.7771 1.37158L9.60894 0.219488C9.31158 -0.0737866 8.82866 -0.0730365 8.53131 0.220988L0.222827 8.46942C0.0745292 8.61569 0 8.8077 0 8.99972C0 9.19173 0.0745292 9.38375 0.222827 9.53001L8.53131 17.7785C8.8279 18.0732 9.31082 18.074 9.60894 17.7799L10.7771 16.6279C11.0714 16.3376 11.0744 15.8665 10.7832 15.5733L4.25501 8.99972Z"/>
                            </svg>
                        </span>
                        <span className={'button-text'}>
                            {t('xxx_back')}
                        </span>
                    </button>
                </div>
            }

            {this.state.processing &&
                <div className='o-site-wrap'>
                    <div className="u-generic-text u-text-center u-btm-buffer">
                        {t('idpal_uploading')}...
                    </div>
                    <div className="u-text-center">
                        <img style={{width:'auto'}} alt='success' src={ uploadingPng } />
                    </div>
                </div>
            }

            {this.props.submissionAttempts <= 0  &&
            <div className='u-generic-text  u-text-center'>
                {t('idpal_out_of_attempts')}
            </div>
            }

            {!this.state.processing && !isMobile && this.props.submissionAttempts > 0 &&
                <div className='capture-upload-page u-flex u-flex--row u-flex--vh u-flex--center p-success'>
                    <div className='m-center'>

                        <div className="u-generic-text  u-text-center">
                            <CustomButton className={'btn'} label = {t('idpal_retry')} handleClick = {() => this.retryPhoto()} />
                        </div>

                    </div>
                </div>
            }

            <video id="acuant-player" controls autoPlay playsInline style={{display:'none' }}></video>
            <div style={{ textAlign:'center' }}>
                <canvas id="acuant-video-canvas" width="100%" height="auto"></canvas>
            </div>

            <Navigator page={'document_capture'} action={this.state.navigation.action} propsToPass={this.state.navigation.props}/>

        </Fragment>
      )
    }
}

function mapStateToProps(state) {
    return {
        submissionAttempts: state.submissionState.submissionAttempts,
        cropSettings: state.config.cropSettings
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setCaptured }, dispatch);
}

export default withNamespaces('translation')(connect(mapStateToProps, mapDispatchToProps)(AcuantReactCamera));
