import i18next         from 'i18next';
import { environment } from '../../config/environment';
import { documents }   from '../../config/documents';
import  _              from 'lodash';

/**
 * Validation messages for default scenarios.
 *
 * @type object
 * @private
 */
let _messages = {
    success: ['idpal_ensure_all_text_is_visible'],
    propertiesNotSet: ['idpal_ensure_image_was_not_processed'],
}

/**
 * Operator functions, used to execute validation rules.
 *
 * a = rule
 * b = image property
 *
 * @type object
 * @private
 */
let _operations = {
    '>=': (a, b) => { return a >= b },
    '<=': (a, b) => { return a <= b },
};

/**
 * Retrieve document width (for user notification).
 *
 * Formula:
 * floor ( document width (inches) * DPI (min) )
 *
 * @param properties  - image properties
 * @returns {*}
 * @private
 */
let _documentWidthInPixels = (properties) => {
    return _.floor(_.find(documents, (object) => {
        return object.id === properties.documentId;
    }).dimensions['inches'].width * environment.dpiMin);
};

/**
 * Set min DPI allowed for submitted document.
 *
 * @param properties - {
 *     originalImage,
 *     croppedImage,
 *     croppedImageWidth,
 *     croppedImageHeight,
 *     isPassport,
 *     document,
 * }
 * @returns {*}
 * @private
 */
let _minDocumentDpi = (properties) => {
    return properties.document.dpi.min || environment.dpiMin;
}

/**
 * Set max DPI allowed for submitted document.
 *
 * @param properties - {
 *     originalImage,
 *     croppedImage,
 *     croppedImageWidth,
 *     croppedImageHeight,
 *     isPassport,
 *     document,
 * }
 * @returns {*}
 * @private
 */
let _maxDocumentDpi = (properties) => {
    return properties.document.dpi.max || environment.dpiMax;
}

/**
 * Rules map used to validate uploaded images.
 *
 * Rule example:
 * {
 *  property: 'dpi',           - refers to an image property the rule is based on
 *  value: environment.dpiMin, - comparison value (which will be checked against an image property)
 *  operator: '<=',            - logical operator to be used in value comparison
 *  messages: [                - error message(s) to inform user post validation
 *      'sting_key',
 *      ['string_key_with_params', {key: value|function}]
 *  ]
 * }
 *
 * @type array
 * @private
 */
let _rules = [

    /**
     * Rule compares image glare against a set threshold
     * to ensure image does not contain to much glare.
     */
    {
        property: 'glare',
        value: environment.glareThreshold,
        operator: '<=',
        messages: [
            'idpal_image_has_glare_please_retry'
        ]
    },

    /**
     * Rule compares image sharpness against a set threshold
     * to ensure image is not blurry.
     */
    {
        property: 'sharpness',
        value: environment.sharpnessThreshold,
        operator: '<=',
        messages: [
            'idpal_image_appears_blurry_please_retry'
        ]
    },

    /**
     * Rule compares image DPI against a set threshold
     * to enforce min DPI.
     */
    {
        property: 'dpi',
        value: _minDocumentDpi,
        operator: '<=',
        messages: [
            'idpal_image_dpi_min_please_retry'
        ]
    },

    /**
     * Rule compares image DPI against a set threshold
     * to enforce max DPI.
     */
    {
        property: 'dpi',
        value: _maxDocumentDpi,
        operator: '>=',
        messages: [
            'idpal_image_dpi_max_please_retry'
        ]
    },
];

/**
 * Set human readable, translated text using localisation library.
 * Support for lang strings to contain dynamic params.
 * Support for values of dynamic params to also be resolved dynamically (ie. with a function).
 *
 * @param messages   - rule messages reference
 * @param properties - image properties
 * @returns {[]}
 * @private
 */
let _toText = (messages, properties = {}) => {

    let readable = [];

    // Process all messages
    for (let i = 0; i < messages.length; i++) {

        // Handling messages with param
        if (Array.isArray(messages[i])) {

            // Define text and params (key value set for dynamic input)
            let text = messages[i][0];
            let params = messages[i][1]

            // Get translated text with dynamic params and dynamic values
            readable.push(i18next.t(
                text,
                _.transform(params, function(result, value, key) {
                    if (value instanceof Function) {
                        result[key] = value(properties);
                    } else {
                        result[key] = value;
                    }
                }, {})
            ));
        } else {

            // Get translated text
            readable.push(i18next.t(messages[i]));
        }
    }

    return readable;
}

/**
 * Validate defined rules and resolve promise containing a message and validation outcome.
 * This function is an async promise, supports rules with various properties as functions
 * that is why "validate" requires to await results from tertiary functions defined in rules.
 *
 * @param properties - image properties
 * @returns {Promise<unknown>}
 * @public
 */
let validate = (properties) => {

    return new Promise ( async (resolve, reject) => {

        // Check if object isset and not empty
        if (Object.keys(properties).length === 0 && properties.constructor === Object) {
            resolve({
                messages: _toText(_messages.propertiesNotSet),
                validated: false
            });
        }

        // Check each rule
        for (let i = 0; i < _rules.length; i++) {

            // Assign rule to var in current scope
            let rule = _rules[i];

            // Check if rule property is a function
            if (rule.property instanceof Function) {

                // Execute the function and resolve rule check in resolved promise
                await rule.property(properties).then((response) => {

                    // Perform validation on a rule (using logical operator checks)
                    let validation = _operations[rule.operator](rule.value, response);

                    // Return failed rule validation with message
                    if (!validation) {
                        resolve({
                            messages: _toText(rule.messages, properties),
                            validated: false
                        });
                    }
                });

            } else if (rule.value instanceof Function) {

                // Perform validation on a rule (using logical operator checks)
                let validation = _operations[rule.operator](rule.value(properties), properties[rule.property]);

                // Return failed rule validation with message
                if (!validation) {
                    resolve({
                        messages: _toText(rule.messages, properties),
                        validated: false
                    });
                }

            } else {

                // Perform validation on a rule (using logical operator checks)
                let validation = _operations[rule.operator](rule.value, properties[rule.property]);

                // Return failed rule validation with message
                if (!validation) {
                    resolve({
                        messages: _toText(rule.messages, properties),
                        validated: false
                    });
                }
            }
        }

        // Resolve validation as successful
        resolve({
            messages: _toText(_messages.success),
            validated: true
        });
    });
};

export default validate;