import React, {Component, Fragment} from 'react';
import Header from "./Header";
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next';
import { sendSuccessMessage } from "./actions/messageActions";

// Images
import loadingBlueSvg from '../assets/img/loading-blue.svg';
import successSvg from '../assets/img/success.svg';


function successText() {
    return <NamespacesConsumer>{(t, { i18n }) => <span>{t('idpal_success')}</span>}</NamespacesConsumer>;
}  
    
function finishText() {
    return <NamespacesConsumer>{(t, { i18n }) => <span>{t('idpal_finish')}</span>}</NamespacesConsumer>;
}

function successImage() {
    if(sessionStorage.getItem('brand') === 'pcs') {
        // return <img alt='success' src={require('../assets/images/pcs/processing.png')} />;
        return <img alt='idscango' src={ loadingBlueSvg } />
    }
    else {
        return <img alt='success' src={ successSvg } />;
    }
   
}

        
        
export default class Success extends Component {

    
    
    clearUserPII() {
        sessionStorage.removeItem('uuid');  
        sessionStorage.removeItem('sessionid');
        sessionStorage.removeItem('submissionid');
        sessionStorage.removeItem('region');
        sessionStorage.removeItem('disableheader');
        sessionStorage.removeItem('passive_liveness_enabled');
     }
    
    
     onboarding() {  
        window.location.reload();
     }

    componentDidMount() {

        sendSuccessMessage('*');
    }
     
    render() {
        this.clearUserPII();
        
        return (
            <Fragment>

                <Header />

                <div className='o-site-wrap'>

                    <div className='u-generic-text  u-text-center'>
                        {successText()}
                    </div>

                    <div className='u-success-logo u-text-center'>
                        {successImage()}
                    </div>

                </div>

            </Fragment>
        )
    }
}



