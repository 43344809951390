import React, { Component, Fragment } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Header from "./Header";
import { getCountries,
    setSelectedCountry,
    submitEVerification,
    storeAddress,
    setFormValue,
    setErrors,
    setPhone } from "./actions/eVerificationActions";
import TextInput from './Inputs/TextInput';
import AddressLookup from './Inputs/AddressLookup';
import DateInput from './Inputs/DateInput';
import SelectInput from './Inputs/SelectInput';
import { withNamespaces } from 'react-i18next';
import { eVerification } from '../config/eVerification.js';
import CustomButton from './Button';
import i18n from './../i18n';
import eVerificationValidatorService from "../services/shared/eVerificationValidatorService";
import loadingGif from '../assets/gifs/loading.gif';
import Label from "./Inputs/Label";
import PhoneInput from "react-phone-input-2";
import Navigator from "./Navigator";

class EVerification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputValue: null,
            uuidExists: false,
            brandingExists: null,
            languageCode: i18n.language,
            loadingError: null,
            navigator: 'load'
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.selectCountry = this.selectCountry.bind(this);
    }

    componentDidMount() {
        if ( this.props.countries.length === 0 ){
            this.props.getCountries();
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            brandingExists: nextProps.branding
        });
    }

    validate(formData) {

        // Set validation results
        let errors = eVerificationValidatorService(formData, this.props.customFields);
        this.props.setErrors(errors);

        // No errors, submit form
        return Object.keys(errors).length === 0;

    }

    handleSubmit(event) {

        let formData = {
            firstName   : this.props.firstName ? this.props.firstName : '' ,
            lastName    : this.props.lastName ? this.props.lastName : '',
            dob         : this.props.dob ? this.props.dob : '',
            address1    : this.props.address1 ? this.props.address1 : '',
            address2    : this.props.address2 ? this.props.address2 : '',
            city        : this.props.city ? this.props.city : '',
            postCode    : this.props.postCode ? this.props.postCode : '',
            country     : this.props.selectedCountry.name ? this.props.selectedCountry.name : '',
            customField : this.props.customField ? this.props.customField : '',
            phone       : this.props.phone ? this.props.phone : '',
            phonePrefix : this.props.phonePrefix ? this.props.phonePrefix : '',
            phoneRaw    : this.props.phoneRaw   ? this.props.phoneRaw   : '',
        }

        event.preventDefault();
        if (this.validate(formData)) {

            // If the selected country is an activated country, submit to be verified
            if (this.props.activatedCountries.includes(this.props.selectedCountry.name)){
                this.props.submitEVerification(formData);
            // If it is not an activated country, then submit to be stored.
            } else {
                this.props.storeAddress(formData);
            }
        }
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( prevProps.eVerificationCompleted !== this.props.eVerificationCompleted) {
            this.setState({
                navigator : "next"
            })
        }
    }

    selectCountry(event) {
        this.props.setSelectedCountry(event.target.value, this.props.countries);
    }

    showAddressLookup() {
        if (!this.props.addressLookupActive) {
            return false
        }
        if (this.props.addressLookupHidden ) {
            return false;
        }
        if (this.props.selectedCountry.supports_address_lookup) {
            return true
        }
    }


    render() {
        const { t } = this.props;

        // Awaiting pending
        if ( this.props.pendingItems > 0 ) {
            return (<div className='u-text-center start-loading'><img alt={'idscango'} src={ loadingGif } className='capture' /></div>);
        }

        // Get date format
        let dateformat = this.props.selectedCountry.iso_3166_2 === "US" ? "mdy" : "dmy";


        return (

            <Fragment>
                <Header/>

                <div className="form-wrap">
                    <Fragment>
                        <h1 className={"u-text-center"}>
                            {t('idpal_please_add_personal_details')}
                        </h1>
                        <form onSubmit={this.handleSubmit}>

                            <div className="form-wrap__inner">
                                <div className="row">
                                    <TextInput config={eVerification.firstName} error={this.props.errors.firstName} onChange={this.props.setFormValue}/>
                                    <TextInput config={eVerification.lastName} error={this.props.errors.lastName} onChange={this.props.setFormValue}/>
                                </div>

                                <div className="row">
                                    <DateInput config={eVerification.dob} error={this.props.errors.dob} format={dateformat} onChange={this.props.setFormValue}/>
                                        <div className={this.props.errors.phone ? 'error input-group' : 'input-group'}>
                                        <Label id={'phone'}
                                               required={true}
                                               label={t('idpal_phone')}
                                               infoTip={t('idpal_phone_info')}  />
                                        <PhoneInput
                                            enableSearch
                                            disableSearchIcon
                                            inputClass={"phone-input"}
                                            placeholder={''}
                                            value={this.state.phone}
                                            onChange={this.props.setPhone}
                                        />

                                        {this.props.errors.phone &&
                                        <span className="error-message">{this.props.errors.phone}</span>
                                        }
                                    </div>
                                </div>

                                { !this.showAddressLookup() &&
                                    <Fragment>
                                        <div className="row">
                                            <TextInput config={eVerification.address1}
                                                       error={this.props.errors.address1}
                                                       value={this.props.address1}
                                                       onChange={this.props.setFormValue}/>

                                            <TextInput config={eVerification.address2}
                                                       error={this.props.errors.address2}
                                                       value={this.props.address2}
                                                       onChange={this.props.setFormValue}/>
                                        </div>

                                        <div className="row">
                                            <TextInput config={eVerification.city}
                                                       error={this.props.errors.city}
                                                       value={this.props.city}
                                                       onChange={this.props.setFormValue}/>

                                            <TextInput config={eVerification.postCode}
                                                       error={this.props.errors.postCode}
                                                       value={this.props.postCode}
                                                       onChange={this.props.setFormValue}/>
                                        </div>
                                    </Fragment>
                                }



                                <div className="row">
                                    <SelectInput onChange={this.selectCountry}
                                                 options={this.props.countries}
                                                 error={this.props.errors.country}
                                                 config={eVerification.country}
                                                 default={this.props.selectedCountry.iso_3166_3}
                                    />

                                    { this.showAddressLookup() &&
                                        <AddressLookup error={this.props.errors.address1}/>
                                    }


                                    {/*Optional custom field based on selected country*/}
                                    {this.props.customFields.map(field =>
                                        <Fragment key={field.iso}>
                                            {this.props.selectedCountry.iso_3166_3 === field.iso &&
                                            <TextInput config={field} error={this.props.errors.customField} onChange={this.props.setFormValue}/>
                                            }
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                            <CustomButton className='btn' label={t('idpal_continue')}/>

                        </form>
                    </Fragment>

                </div>

                <Navigator page={"e_verification"} action={this.state.navigator}/>

            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        uuidValid: state.config.uuidValid,
        limitReached: state.config.limitReached,
        branding: state.config.profile,
        pendingItems: state.spinner.pendingItems,
        customFields: state.eVerification.customFields,
        countries: state.eVerification.countries,
        selectedCountry: state.eVerification.selectedCountry,
        activatedCountries: state.eVerification.activatedCountries,
        addressLookupHidden: state.addressLookup.hidden,
        addressLookupActive: state.addressLookup.active,
        firstName: state.eVerification.firstName,
        lastName: state.eVerification.lastName,
        dob: state.eVerification.dob,
        address1: state.eVerification.address1,
        address2: state.eVerification.address2,
        city: state.eVerification.city,
        postCode: state.eVerification.postCode,
        customField: state.eVerification.customField,
        errors: state.eVerification.errors,
        phone: state.eVerification.phone,
        phonePrefix: state.eVerification.phonePrefix,
        phoneRaw: state.eVerification.phoneRaw,
        acuantInitialized: state.config.acuantInitialized,
        screens: state.submissionState.screens,
        eVerificationCompleted: state.submissionState.submissionState.e_verification.completed,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSelectedCountry,
        getCountries,
        setFormValue,
        setPhone,
        submitEVerification,
        storeAddress,
        setErrors
    }, dispatch);
}


export default withNamespaces('translation')(connect(mapStateToProps, mapDispatchToProps)(EVerification) );