import React, { Component } from 'react';
import { connect } from "react-redux";
import {withNamespaces} from "react-i18next";
import InfoTip from "./InfoTip";

class Label extends Component {

    constructor(props) {
        super(props);
        this.state = {
            color: '',
        }
    }

    componentDidMount() {
        this.setState({
            color: this.props.branding.data.company_branding.primary_color ?
                this.props.branding.data.company_branding.primary_color : this.state.color
        });
    }

    render() {
        const { t } = this.props;

        const textColor = this.state.color ? {
            color : '#' + this.state.color
        } : {};

        return (
            <div>
                <label htmlFor={this.props.id}
                    style={textColor}>
                    {t(this.props.label)}
                    {this.props.required && <>*</>}
                </label>

                {this.props.infoTip &&
                <InfoTip message={this.props.infoTip} />
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        branding: state.config.profile
    };
}

export default  withNamespaces('translation')(connect(mapStateToProps)(Label));
