import React, { Component } from 'react';
import { connect } from "react-redux";
import Label from "./Label.js"
import SimpleDateInput from "./SimpleDateInput";

class DateInput extends Component {

    render() {

        return (
            <div className={this.props.error ? 'error input-group' : 'input-group'}>
                <Label id={this.props.config.id} required={this.props.config.required} label={this.props.config.name} />

                <SimpleDateInput id={this.props.config.id} format={this.props.format} onChange={this.props.onChange}/>

                {this.props.error &&
                <span className="error-message">{this.props.error}</span>
                }
            </div>
        );
    }
}


export default (connect()(DateInput));
