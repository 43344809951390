import i18next         from 'i18next';
import { eVerification }   from '../../config/eVerification';
import { phone } from "phone";

/**
 * Validate eVerification form submission against the form rules
 * return obj
 */
function validate(formData, customFields) {
    let rules = eVerification;

    // If selected country has custom field, add to validation rules.
    if (formData['country']) {
        for (let i = 0; i < customFields.length; i++) {
            if (customFields[i]['countryName'] === formData['country']) {
                rules.customField = customFields[i];
            }
        }
    }

    let fields = Object.keys(rules);

    let errors = {};

    // Validate fields against rules
    for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        let rule = rules[field];
        let formField = formData[field];

        if (formField !== null && formField.trim() !== "") {

            // Check date is valid
            if (rule['type'] === 'date') {

                // Check for missing day month or year
                let dateArray = formField.split("-");
                for (let x = 0; x < dateArray.length; x++) {
                    if (dateArray[x] === "00") {
                        errors[field] = i18next.t('idpal_invalid_date_error');
                    }
                }
                // Check that date is a valid date
                let formDate = new Date(formField);
                if(formDate instanceof Date && isFinite(formDate)) {
                    if (new Date(formField) < new Date(rule['minDate'])) {
                        errors[field] = i18next.t('idpal_minimum_date_error', {date: rule['minDate']})
                    } else if (new Date(formField) > new Date()) {
                        errors[field] = i18next.t('idpal_maximum_date_error');
                    }
                } else {
                    errors[field] = i18next.t('idpal_invalid_date_error');
                }
            }
            // Check phone is valid
            if (rule['type'] === 'phone') {
                if (!phone("+" + formField).isValid) {
                    errors[field] = i18next.t('idpal_please_enter_valid_number');
                }
            }
            // Check that field is above minimum character count
            if (formField.length < rule['min']) {
                errors[field] = i18next.t('idpal_minimum_characters_error', {count: rule['min']});

            // Check that field is below maximum character count
            } else if (formField.length > rule['max']) {
                errors[field] = i18next.t('idpal_maximum_characters_error', {count: rule['max']});

            // Check that field matches allowed characters
            } else if (rule['regex'] !== null ) {
                let regex = new RegExp(rule['regex']);
                if (!regex.test(formField)) {
                    errors[field] = i18next.t(rule['messages']['regex']);
                }
            }

        // Check that required field is not empty
        } else if ((formField === null || formField.trim() === "") && rule['required'] ) {
            errors[field] = errors[field] = i18next.t('idpal_field_is_required')
        }
    }
    return errors;
}

export default validate;