import React, { Component, Fragment } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Header from "./Header";
import { withNamespaces } from 'react-i18next';
import CustomButton from './Button';
import i18n from './../i18n';
import Navigator from "./Navigator";
import {submissionStarted} from "./actions/submissionStateActions";

// Images
import logo from '../assets/img/idpal_logo.svg';

const languages = [
    { label: "Français", value: "fr" },    
    { label: "English", value: "en" },
    { label: "España", value: "es" }
];

 
class StartScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            languageCode: i18n.language,
            navigation: {
                action: "load",
                props: null
            }
        }
        this.switchLanguage = this.switchLanguage.bind(this);
        this.continue = this.continue.bind(this);
    }

    stripSlashes(string) {
        return string.replace(new RegExp("\\\\", "g"), "");
    }

    getCompanyName() {
        if (this.props.branding.name !== "") {
            return this.stripSlashes(this.props.branding.name);
        } else {
            return "ID‑Pal"
        }
    }
      
    switchLanguage(event) {
        i18n.changeLanguage( event.target.value );
        this.setState({languageCode: event.target.value});
    }

    startOrContinueString() {
        if(this.props.submission.new) {
            return "idpal_start"
        } else {
            return "idpal_continue"
        }
    }

    continue() {
        this.props.submissionStarted();
        this.setState({
            navigation: {
                action: "next"
            }
        });
    }

    render() {
        const { t } = this.props;

        return (

            <Fragment>
                <Header />
      
                <div className='o-site-wrap'>

                    <div className={'start-screen'}>

                        <div className="o-entryBtns">
                                <div className='u-generic-text  u-text-center u-lg-btm-buffer'>
                                    {t('idpal_start_screen_blurb', {company: this.getCompanyName()} )}
                                </div>

                        </div>

                        <CustomButton
                            id="continue"
                            className='btn btn__arrow'
                            label={t(this.startOrContinueString())}
                            handleClick={this.continue} />

                        <div className={'small-text light-text policies'}>
                            {t('idpal_by_clicking_you_agree')}
                            <a href={this.props.policies.terms_and_conditions} target={'_blank'}>
                                {t('idpal_terms_and_conditions')}</a>. &nbsp;

                            {t('idpal_read_our_data_protection')}
                            <a href={this.props.policies.privacy_policy} target={'_blank'}>
                                {t('idpal_here')}</a>.
                        </div>

                        {!this.props.branding.whitelabel &&
                        <div className={'logo-section'}>
                            {t('idpal_powered_by')}
                            <img className='small-logo' src={logo} alt={"ID-Pal"}/>
                        </div>
                        }

                        <div className='u-generic-text  u-text-center languageSelection'>

                            <label>Language:</label>
                            <select value={this.state.languageCode} onChange={this.switchLanguage}>
                                {languages.map((lang) =>
                                    <option value={lang.value} key={lang.value}>{lang.label}</option>
                                )}
                            </select>

                        </div>
                    </div>
                </div>

                <Navigator page={"start_screen"} action={this.state.navigation.action} propsToPass={this.state.navigation.props}/>

            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        submission: state.submissionState.details,
        branding: state.config.profile.data.company_branding,
        policies: state.config.profile.data.policies,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        submissionStarted
    }, dispatch);
}


export default withNamespaces('translation')(connect(mapStateToProps, mapDispatchToProps)(StartScreen) );