export function hideAddressLookup() {
    return (dispatch) => {
        dispatch({
            type: "HIDE_ADDRESS_LOOKUP"
        });
    }
}

export function resetAddressLookup() {
    return (dispatch) => {
        dispatch({
            type: "RESET_ADDRESS_LOOKUP"
        });
    }
}

export function setFullResult(string) {
    return (dispatch) => {
        dispatch({
            type: "FULL_RESULT_RECEIVED",
            data: string
        });
    }
}

export function setEVerificationAddress(address) {
    return (dispatch) => {
        dispatch({
            type: "SET_ADDRESS_FROM_LOOKUP",
            data: address
        });
    }
}

export function clearEVerificationAddress() {
    let address = {
        address1: "",
        address2: "",
        locality: "",
        postalCode: ""
    }
    return (dispatch) => {
        dispatch({
            type: "SET_ADDRESS_FROM_LOOKUP",
            data: address
        });
    }
}