export function initialiseState(data) {
    return (dispatch) => {
        dispatch(setSubmissionState(data));
        dispatch(setRetryLimit(data.state.document_upload.remaining));

        return dispatch({
            type: "COMPLETE",
        })
    }
}

export function setSubmissionState(data) {
    return {
        type: "SET_SUBMISSION_STATE",
        data: data
    }
}

export function useRetry() {
    return {
        type: "USE_RETRY"
    }
}

export function setRetryLimit(limit) {
    return {
        type: "SET_RETRY_LIMIT",
        data: limit
    }
}

export function submissionStarted() {
    return {
        type: "SUBMISSION_STARTED"
    }
}

