import React, { Component, Fragment } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Header from "./Header";
import { decrementSidesLeft, setCardOrientation, setCardType } from "./actions/idPropertiesActions";
import { setInstanceID, submitBackID, submitFrontID } from "./actions/configActions";
import Processing from "./Processing";
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next';
import CustomButton from './Button';
import Navigator from "./Navigator";

// Images
import drivingLicenseCaptureProcessGif from '../assets/video/driving_licence_capture_process.gif';
import idCardFrontJpg from '../assets/img/id_card_front.jpg';
import idCardBackJpg from '../assets/img/id_card_back.jpg';
import { useRetry } from "./actions/submissionStateActions";


class CapturePhoto extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputValue: null,
            processing: false,
            navigation: {
                action: 'load',
                props: null
            }
        }
    }

    isIEorEDGE() {
        return navigator.appName === 'Microsoft Internet Explorer' || (navigator.appName === "Netscape" && navigator.appVersion.indexOf('Edge') > -1);
    }

    componentDidMount() {
        // if (!this.props.instanceID) {
        //     this.props.setInstanceID();
        // }
        if (this.props.location && this.props.location.state) {
            if (this.props.location.state.isRetry) {
                this.props.useRetry();
                this.navigateCamera();
            }
        }
    }

    getOrientationCopy() {
        const { t } = this.props;
        return this.props.orientation === 0 ?  t('xxx_front') : t('xxx_back');
    }

    getCardTypeCopy() {
        
        const { t } = this.props;
        
        switch (this.props.cardType) {
            case 1:
                return t('xxx_id_card'); // passport 
            case 2:
                return 'medical card';
            case 3:
                return t('idpal_national_id'); // new for id's licences
            case 31:
                return t('idpal_driving_licence'); // new for id's licences
            case 32:
                return t('idpal_resident_card'); // new for id's licences
            default:
                return t('xxx_id_card')
        }
    }

    openCamera(type){
        this.props.setCardType(type);
        this.navigateCamera();
    }

    navigateCamera(){
        this.setState({
            navigation: {
                action: 'next',
                props: {
                    documentId: this.props.location.state.documentId
                }
            }
        })
    }

    render() {
        if (this.state.processing) {
            return <Processing />
        }
        const { t } = this.props;
        
        return (
            <Fragment>

                <Header />

                <div className='o-site-wrap'>

                    <div className='u-generic-text  u-text-center'>
                        {t('xxx_uploadclearpic')} {this.getOrientationCopy()} {t('xxx_of_your')} {this.getCardTypeCopy()}.
                    </div>

                    <div className='u-display-upload u-text-center'>
                        {this.props.sidesLeft === 2 &&
                            <img alt='idscango' className={'capture'} src={ drivingLicenseCaptureProcessGif } />
                        }
                        {this.props.sidesLeft === 1 &&
                            <img alt='idscango' className={'capture'} src={this.props.frontSubmitted ? idCardFrontJpg : idCardBackJpg} />
                        }
                    </div>

                    <div className="o-entryBtns">

                        {this.props.sidesLeft === 2 &&
                            <Fragment>
                                {process.env.REACT_APP_IDPASSPORT_ENABLED === 'true' &&
                                    <CustomButton className='btn' handleClick = {() => this.openCamera(1)} label= 'Capture ID/Passport' />
                                }
                                {process.env.REACT_APP_MEDICAL_CARD_ENABLED === 'true' &&
                                    <CustomButton className='btn' handleClick = {() => this.openCamera(2)} label= 'Capture Medical Card' />
                                }
                            </Fragment>
                        }

                        {this.props.sidesLeft === 1 &&
                            <CustomButton className='btn' handleClick = {() => this.openCamera(this.props.cardType)} label= {t('idpal_continue')} />
                        }
                        {this.props.sidesLeft === 1 && this.props.cardType === 2 &&
                            <div className={'btn outline'} onClick={() => { this.props.history.push('/results/medicard') }}>
                                <p className={'buttonBdText'}>
                                    {t('xxxx_skipstep')}
                                </p>
                            </div>
                        }

                    </div>

                </div>

                <Navigator page={'capture_photo'} action={this.state.navigation.action} propsToPass={this.state.navigation.props} />

            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        orientation: state.idProperties.orientation,
        cardType: state.idProperties.cardType,
        sidesLeft: state.idProperties.sidesLeft,
        frontSubmitted: state.config.frontSubmitted,
        backSubmitted: state.config.backSubmitted
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setCardType, setInstanceID, setCardOrientation, decrementSidesLeft, submitFrontID, submitBackID, useRetry }, dispatch);
}

export default withNamespaces('translation')(connect(mapStateToProps, mapDispatchToProps)(CapturePhoto) );