import React, { Component, Fragment } from 'react';

const rules = {
    month : {
        max_digits: 2,
        max: 12
    },
    day : {
        max_digits: 2,
        max: 31
    },
    year: {
        max_digits: 4,
        max: 99999
    }
}

class SimpleDateInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            display : {
                month: "",
                day : "",
                year: ""
            },
            month: 0,
            day: 0,
            year: 0,
            keysPressed: []
        }
        this.update = this.update.bind(this);
        this.leave = this.leave.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    // Update formatted string values for display in input
    updateDisplayValues(property, allowZeros) {
        let val = "";

        if (allowZeros) {
            val = this.pad(this.state[property], rules[property].max_digits);
        } else {
            val = this.state[property] === 0
                ? ""
                : this.pad(this.state[property], rules[property].max_digits);
        }

        let display = this.state.display;
        display[property] = val;

        this.setState({
            display: display
        })
    }

    // Add appropriate amount of leading 0's to string value
    pad(x, length) {
        x = x.toString();
        while (x.length < length ) {
            x = "0" + x;
        }
        return x;
    }

    // Returns true if value contains only numerical characters
    isOnlyDigits(x) {
        let pattern = new RegExp('^[0230-9]*$');
        return pattern.test(x);
    }

    // Depending on current element and format, switch focus to next element
    focusNext(current) {
        let next = "";
        if (current === "month") {
            next = (this.props.format === "mdy") ? "day" : "year";
        } else if (current === "day") {
            next = (this.props.format === "mdy") ? "year" : "month";
        }

        let element = document.getElementById(next);
        if (element) {
            element.focus();
            element.click();
            element.setSelectionRange(0, element.value.length);
        }
    }

    // Set value to nearest allowed value
    forceMinMax(val, min, max) {
        val = parseInt(val);

        val = val ? val : 0;
        val = (val > max) ? max : val;
        val = (val < min) ? min : val;

        return val;
    }

    // Clean up input display when focus is lost
    leave(event) {
        let property = event.target.id;
        this.updateDisplayValues(property, false);
        this.updateValue();
        this.setState({keysPressed : []})
    }

    // Check if input indicates automatically switching to next input
    shouldFocusNext(value, rule) {

        // If previous key press was a 0 proceed to next input
        // 0 then 1 => "01"
        if ([48, 229].includes(this.state.keysPressed[this.state.keysPressed.length-1])) {
            return true;

        // If first digit of input is greater than the max first digit, proceed to next input
        // 5 assumed "05", not allowed to typed "50"
        } else if (parseInt(value.toString()[0]) > parseInt(rule.max.toString()[0])) {
            return true;
        }
        return false
    }

    // Store previous keys pressed for this input to determine leading zero
    handleKeyPress(event) {
        let keysPressed = this.state.keysPressed;
        keysPressed.push(event.keyCode);
        this.setState({
            keysPressed: keysPressed
        })
    }

    update(event) {
        let value = event.target.value;
        let property = event.target.id;
        let rule = rules[property];

        // Only save input if it is digits
        if (this.isOnlyDigits(value)) {

            // Don't allow values over max, zero is allowed so they can keep typing
            value = this.forceMinMax(value, 0, rule.max);

            this.setState({
                [property] : value
            },() => {
                this.updateDisplayValues(property, true);
                this.updateValue();

                // If max digits have been entered, or value entered indicates moving to next input.
                if ( value.toString().length >= rule.max_digits || this.shouldFocusNext(value, rule) ) {
                    this.focusNext(property);
                }
            });
        }
    }

    selectAll(event){
        event.target.setSelectionRange(0, event.target.value.length);
    }

    updateValue() {
        // Format Date
        let date = `${this.pad(this.state.year, rules.year.max_digits)}-${this.pad(this.state.month, rules.month.max_digits)}-${this.pad(this.state.day, rules.day.max_digits)}`;

        // Trigger onChange function of hidden input
        let hiddenInput = document.getElementById(this.props.id);
        let nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
        nativeInputValueSetter.call(hiddenInput, date);

        hiddenInput.dispatchEvent( new Event('input', { bubbles: true }));
    }


    render() {
        return (

            <div className={"simple-date-input"}>

                {this.props.format === "mdy" &&
                <Fragment>
                    <input id={"month"}
                           className={"two-digit"}
                           type={"text"}
                           placeholder="MM"
                           pattern="[0-9]*"
                           inputMode="numeric"
                           value={this.state.display.month}
                           onKeyUp={this.handleKeyPress}
                           onFocus={this.selectAll}
                           onChange={this.update}
                           onBlur={this.leave}/>
                    /
                    <input id={"day"}
                           className={"two-digit"}
                           type={"text"}
                           placeholder="DD"
                           pattern="[0-9]*"
                           inputMode="numeric"
                           value={this.state.display.day}
                           onKeyUp={this.handleKeyPress}
                           onFocus={this.selectAll}
                           onChange={this.update}
                           onBlur={this.leave}/>

                </Fragment>
                }

                {this.props.format !== "mdy" &&
                <Fragment>
                    <input id={"day"}
                           className={"two-digit"}
                           type={"text"}
                           placeholder="DD"
                           pattern="[0-9]*"
                           inputMode="numeric"
                           value={this.state.display.day}
                           onKeyUp={this.handleKeyPress}
                           onFocus={this.selectAll}
                           onChange={this.update}
                           onBlur={this.leave}/>
                    /
                    <input id={"month"}
                           className={"two-digit"}
                           type={"text"}
                           placeholder="MM"
                           pattern="[0-9]*"
                           inputMode="numeric"
                           value={this.state.display.month}
                           onKeyUp={this.handleKeyPress}
                           onFocus={this.selectAll}
                           onChange={this.update}
                           onBlur={this.leave}/>

                </Fragment>
                }

                /
                <input id={"year"}
                       className={"four-digit"}
                       type={"text"}
                       placeholder="YYYY"
                       pattern="[0-9]*"
                       inputMode="numeric"
                       value={this.state.display.year}
                       onFocus={this.selectAll}
                       onChange={this.update}
                       onBlur={this.leave}/>

                <input type={"text"} hidden id={this.props.id} onChange={this.props.onChange}/>

            </div>

        );
    }
}

export default (SimpleDateInput);