import React, {Component, Fragment} from 'react';
import Header from "./Header";
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next';

// Images
import loadingGif from '../assets/img/loading.gif';
import loadingIdcardGif from '../assets/gifs/loading_idcard.gif';


function AnalyzingString() {
    return <NamespacesConsumer>{(t, { i18n }) =>
        <span>{t('idpal_analyzing')}</span>}
    </NamespacesConsumer>;
}

    
  
export default class Processing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imageUrl: null,
        }
    }

    componentDidMount() {
        if(this.props.document.properties.documentId === 1) {
            this.setState({
                imageUrl:  loadingGif,
            });
        }
        else {
            this.setState({
                imageUrl:  loadingIdcardGif,
            });
        }
    }

    render() {
        return (
            <Fragment>

                <Header />

                <div className='o-site-wrap'>

                    <div className='u-generic-text  u-text-center u-btm-buffer'>
                        { AnalyzingString()  }
                    </div>

                    <div className='u-display-analysing u-text-center'>
                        <img alt={'idscango'} src={this.state.imageUrl } className='capture' />
                    </div>

                </div>

            </Fragment>
        )
    }
}