import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import TextInput from "./TextInput";
import i18n from "../../i18n";
import ApiService from "../../services/api/api";
import loadingGif from "../../assets/gifs/loading.gif";
import { withNamespaces } from "react-i18next";
import addressLookupValidatorService from "../../services/shared/addressLookupValidatorService.js";
import {
    clearEVerificationAddress,
    hideAddressLookup,
    setEVerificationAddress,
    setFullResult
} from "../actions/addressLookupActions";
import { setErrors } from "../actions/eVerificationActions";
import { bindActionCreators } from "redux";
import Label from "./Label";


class AddressLookup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timer: null,
            showResults: false,
            showSpinner: true,
            loadingMessage: "",
            results: [],
            noResults: false,
            resultString: ""
        }
        this.inputUpdated = this.inputUpdated.bind(this);
        this.lookupAddress = this.lookupAddress.bind(this);
        this.propmtKeepTyping = this.propmtKeepTyping.bind(this);
        this.close = this.close.bind(this);
        this.clearFullResult = this.clearFullResult.bind(this);

    }

    componentDidMount() {
        this.setState({
            color: this.props.branding.data.company_branding.primary_color ?
                this.props.branding.data.company_branding.primary_color : this.state.color
        });
    }

    inputUpdated(event) {
        this.props.clearEVerificationAddress();
        clearTimeout(this.state.timer);
        this.setState({
            loadingMessage: "",
            showResults: true,
            showSpinner: true,
            results: [],
            noResults: false,
            resultString: event.target.value
        });

        if (event.target.value.length < 5) {
            this.state.timer = setTimeout(this.propmtKeepTyping, 5000)
        } else {
            this.state.timer = setTimeout(this.lookupAddress, 1000, event.target.value)
        }
    }

    propmtKeepTyping() {
        this.setState({loadingMessage: "idpal_keep_typing"})
    }

    lookupAddress(address) {
        ApiService.lookupAddress(address, this.props.selectedCountry.iso_3166_2)
            .then(response => {
                if (response.data) {
                    if (response.data.addresses) {
                        this.setState({
                            results: response.data.addresses,
                            showSpinner: false
                        })
                        if (response.data.addresses.length === 0) {
                            this.setState({
                                noResults: true
                            })
                        }
                    }
                } else {
                    this.setState({
                        noResults: true
                    })
                }
             }
        )
    }

    getPlaceholder(countryName) {
        switch (countryName) {
            case 'Ireland' :
                return 'idpal_address_lookup_placeholder_ireland'
            case 'United Kingdom' :
                return 'idpal_address_lookup_placeholder_uk'
            default :
                return 'idpal_address_lookup_placeholder_default'
        }
    }


    selectAddress(address) {
        let errors = addressLookupValidatorService(address);
        this.props.setEVerificationAddress(address);

        // No errors, keep address in input
        if (Object.keys(errors).length === 0) {
            this.props.setFullResult(address.address);
            this.setState({
                resultString: address.address,
                showResults: false
            });

        // Missing field, show full form
        } else {
            this.close();
            this.props.setErrors(errors);
        }
    }

    close(){
        clearTimeout(this.state.timer);
        this.props.hideAddressLookup()
    }

    clearFullResult() {
        this.props.setFullResult("");
        this.props.clearEVerificationAddress();
        this.setState({
            showResults: true
        })
    }

    render() {
        const { t } = this.props;

        const textColor = this.state.color ? {
            color : '#' + this.state.color
        } : {};

        const config = {
            id: 'address_lookup',
            name: 'idpal_address',
            required: 'true'
        }

        return (
            <div className="address-lookup">
                {!this.props.fullResult &&
                    <TextInput
                        config={config}
                        error={this.props.error}
                        value={this.state.resultString}
                        autocomplete="new-password" // Don't like this, but chrome ignores other ways of preventing autofill
                        placeholder={this.getPlaceholder(this.props.selectedCountry.name)}
                        onChange={this.inputUpdated}
                        />
                }
                { this.props.fullResult &&
                    <div className="input-group">
                        <Label id={config.id} required={config.required} label={config.name}/>
                        <div className="full-result" onClick={this.clearFullResult}>{this.state.resultString}</div>
                    </div>
                }


                {this.state.showResults &&
                    <div className={this.state.showResults ? 'address-results' : 'address-results hide'}>
                        {this.state.showSpinner &&
                        <div className="loading">
                            <img alt={'loading'} src={ loadingGif }/>
                            {this.state.loadingMessage &&
                            <span className={"message"}>{t(this.state.loadingMessage)}</span>
                            }
                        </div>
                        }

                        {this.state.results && !this.state.noResults &&
                        <div className="results">
                            {this.state.results.map(result =>
                                <div className="result"
                                     key={result.address}
                                     onClick={() => this.selectAddress(result)}
                                >
                                    {result.address}
                                </div>
                            )}
                        </div>
                        }

                        {this.state.noResults &&
                        <div className="results">
                            <div className="result empty" >{t("idpal_no_results")} </div>
                        </div>
                        }

                        <span className="close"
                              style={textColor}
                              onClick={this.close}
                        >
                            {t("idpal_enter_address_manually")}
                        </span>

                    </div>
                }

            </div>

        );
    }
}
function mapStateToProps(state) {
    return {
        selectedCountry: state.eVerification.selectedCountry,
        fullResult: state.addressLookup.fullResult,
        branding: state.config.profile
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        hideAddressLookup,
        setEVerificationAddress,
        setFullResult,
        setErrors,
        clearEVerificationAddress
    }, dispatch);
}
export default  withNamespaces('translation')(connect(mapStateToProps, mapDispatchToProps)(AddressLookup));
