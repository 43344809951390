import React, { Component, Fragment } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Header from "./Header";
import { withNamespaces } from 'react-i18next';
import CustomButton from './Button';
import i18n from './../i18n';
import Navigator from "./Navigator";
import PassportSVG from "./SVG/Passport";
import DriversLicenseFrontSVG from "./SVG/DriversLicenseFront";
import DriversLicenseBackSVG from "./SVG/DriversLicenseBack";
import NationalIdFrontSVG from "./SVG/NationalIdFront";
import NationalIdBackSVG from "./SVG/NationalIdBack";

class Instructions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navigation: {
                action: 'load',
                props: null,
            }
        }

        this.continue = this.continue.bind(this);

    }
    
    componentDidMount() {

    }

    getDriversLicensePadding() {
        let string = i18n.t("idpal_driving_licence");
        return( 127 - ((string.length - 15) * 4.75 ));
    }

    continue() {
        this.setState({
            navigation: {
                action: "next",
                props: {
                    documentId: this.props.location.state.documentId,
                    isRetry: this.props.location.state.isRetry
                }
            }
        });
    }


    render() {
        const { t } = this.props;

        return (

            <Fragment>

                <Header />
                <div className='o-site-wrap instructions'>
                    {this.props.match.params.type === "1" &&
                    <Fragment>
                        <PassportSVG title={t("idpal_passport").toUpperCase()} />
                        <p className={"u-text-center"}>{t("idpal_front_instructions")}</p>
                    </Fragment>
                    }

                    {this.props.match.params.type === "31" && this.props.match.params.side === "1" &&
                    <Fragment>
                        <DriversLicenseFrontSVG title={t("idpal_driving_licence").toUpperCase()} padding={this.getDriversLicensePadding()} />
                        <p className={"u-text-center"}>{t("idpal_front_instructions")}</p>
                    </Fragment>
                    }

                    {this.props.match.params.type === "31" && this.props.match.params.side === "2" &&
                    <Fragment>
                        <DriversLicenseBackSVG />
                        <p className={"u-text-center"}>{t("idpal_drivers_license_back_instructions")}</p>
                    </Fragment>
                    }

                    <CustomButton id="continue" className='btn btn__arrow' label = {t('idpal_open_camera')} handleClick = {this.continue} />
                </div>

                <Navigator page={'instructions'} action={this.state.navigation.action} propsToPass={this.state.navigation.props} />

            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ }, dispatch);
}

export default withNamespaces('translation')(connect(mapStateToProps, mapDispatchToProps)(Instructions) );