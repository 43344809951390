import React, { Component } from 'react';
import { connect } from "react-redux";
import Label from "./Label";
import {withNamespaces} from "react-i18next";

class TextInput extends Component {

    render() {
        const { t } = this.props;

        return (
            <div className={this.props.error ? 'error input-group' : 'input-group'}>
                <Label id={this.props.config.id} required={this.props.config.required} label={this.props.config.name} infoTip={this.props.infoTip} />
                <input
                    type="text"
                    id={this.props.config.id}
                    onChange={this.props.onChange}
                    placeholder={t(this.props.placeholder)}
                    autoComplete={this.props.autocomplete}
                    value={this.props.value}/>


                {this.props.error &&
                    <span className="error-message">{this.props.error}</span>
                }

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        branding: state.config.profile
    };
}

export default  withNamespaces('translation')(connect(mapStateToProps)(TextInput));
