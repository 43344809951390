import { documents } from '../../config/documents';
import  _            from 'lodash';

let documentService = {

    /**
     * Retrieve a document by documentId
     *
     * @param documentId
     * @returns {*}
     */
    findById: (documentId) => {
        return _.find(documents, function(document) {
            return document.id === documentId;
        });
    },

    /**
     * Retrieve a document by classification (from API)
     *
     * @param classification - official document classification object
     *
     * @returns {*}
     */
    findByClassification: (classification) => {
        return _.find(documents, function(document) {
            return document.name === classification.type_classname || document.name === classification.type_name;
        });
    },

    /**
     * Cast canvas image to a blob
     *
     * @param url
     * @returns {Blob}
     */
    urlToBlob: (url) => {
        let binary = atob(url.split(',')[1]);
        let array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: 'image/jpg' });
    },
}

export default documentService;