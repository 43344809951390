import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setInstanceID } from "./actions/configActions";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logo: this.props.branding ? this.props.branding.data.company_branding.logo : null
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.branding && nextProps.branding.hasOwnProperty('data')) {
            this.setState({
                logo: nextProps.branding.data.company_branding.logo
            });
        }
    }

    render() {

        let { logo } = this.state;

        return (
            <>
                { this.props.showHeader === true &&
                <header className='mastHead'>
                    {(() => {
                        if (logo) {
                            return (
                                <div className='logo'>
                                    <img alt='app logo' className='image' src={`data:image/png;base64,${logo}`}/>
                                </div>
                            )
                        } else {
                            return (
                                <div className='logo default-logo'></div>
                            )
                        }
                    })()}
                </header>
                }
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        branding: state.config.profile,
        showHeader: state.config.showHeader
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setInstanceID }, dispatch);
}

export default (connect(mapStateToProps, mapDispatchToProps)(Header));
