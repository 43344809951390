import React, {Component, Fragment} from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {resetProcessedData} from "../actions/processDataActions";
import {resetConfig} from "../actions/configActions";
import {resetIDProperties} from "../actions/idPropertiesActions";
import { withNamespaces } from 'react-i18next';

// Images
import iconAttention2xPng from '../../assets/images/icon_attention@2x.png';
import imageCorrectJpg from '../../assets/images/image_correct.jpg';
import iconChecked2xPng from '../../assets/images/icon_checked@2x.png';
import imageIncorrectJpg from '../../assets/images/image_incorrect.jpg';

class DefaultError extends Component {

    constructor(props) {
        super(props);

        this.state = {
            retryLastStep: false
        };

        this.retry = this.retry.bind(this);
    }

    retry() {
        if (!this.state.retryLastStep) {
            this.props.resetConfig();
            this.props.resetIDProperties();
            this.props.resetProcessedData();
            this.props.history.push('/');
        } else {
            this.props.history.goBack();
        }
    }

    componentDidMount() {
        let {history} = this.props;
        if (history && history.location && history.location.state) {
            this.setState({
                retryLastStep: history.location.state.retryLastStep
            })
        }
    }

    render() {
        
        const { t } = this.props;
        
        return (
            <Fragment>
                 <div className='o-site-wrap'>

                        <div className='u-generic-text  u-text-center'>
                            <img alt='idscango' className='icon' src={ iconAttention2xPng }/>
                            <p className='description'>
                                {t('idpa_unable_to_detect_id')}
                            </p>
                        </div>
                        
                        <div className='instructions'>
                            <ul>
                                <li>
                                    {t('idpal_place_id_close_to_device')}
                                </li>
                                <li>
                                    {t('idpal_ensure_sufficient_light')}
                                </li>                                
                                <li>
                                    {t('idpal_hold_device_steady')}
                                </li>  
                                <li>
                                    {t('idpal_make_sure_all_edges_of_the_id_are_visible')}
                                </li>  
                                <li>
                                    {t('idpal_make_sure_there_are_no_glare_and_shadows_on_the_id')}
                                </li> 
                            </ul>
                            <div className='example_list'>
                                <div className='example'>
                                    <img alt='idscango' className='image' src={ imageCorrectJpg }/>
                                    <div className="example_text">
                                        <img alt='idscango' className='icon' src={ iconChecked2xPng }/>
                                        <p>
                                        {t('idpal_correct')}
                                        </p>
                                    </div>
                                </div>
                                <div className='example'>
                                    <img alt='idscango' className='image' src={ imageIncorrectJpg }/>
                                    <div className="example_text">
                                        <img alt='idscango' className='icon' src={ iconAttention2xPng }/>
                                        <p>
                                            {t('idpal_incorrect')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'btn'} onClick={this.retry} >
                            <p className={'buttonBdText'}>
                                {t('idpal_retry')}
                            </p>
                        </div>
                    </div>
           
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({resetProcessedData, resetConfig, resetIDProperties}, dispatch);
}

export default withNamespaces('translation')(connect(mapStateToProps, mapDispatchToProps)(DefaultError) );

