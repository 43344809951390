import request from '../shared/request';
import { isMobile, isTablet } from "react-device-detect";

var pjson = require('../../../package.json');

function getDocInstance() {
    
    var bodyFormData = new FormData();
        bodyFormData.set('uuid', sessionStorage.getItem('uuid'));
        bodyFormData.set('device_type', "web" );
    
    return request({
        url: '/webapp/session',
        method: "POST",
        headers: {'Content-Type': 'multipart/form-data' },
        data: bodyFormData        
    });
}

function postImage(instanceID, side, file, cropped, documentRegion) {
    let sourceType;
    let bodyFormData = new FormData();

    bodyFormData.set( cropped ? 'image' : 'imageUncropped', file );

    if (isMobile || isTablet) {
        sourceType = 'webapp-capture';
    }
    else {
        sourceType = 'webapp-upload';
    }
    bodyFormData.set('source_type', sourceType);
    bodyFormData.set('submissionId', sessionStorage.getItem('submissionid'));

    return request({
        url: "/webapp/document/verification/0/passport/",
        method: 'POST',
        data: bodyFormData
    })
}

// ID-PAL: Passing either cropped and uncropped ID Card images for validation
function processDLDuplex(instanceID, side, file, cropped, documentRegion) {
    let url;
    let sourceType;
    var bodyFormData = new FormData();
    if (isMobile || isTablet) {
        sourceType = 'webapp-capture';
    }
    else {
        sourceType = 'webapp-upload';
    }
    bodyFormData.set('source_type', sourceType);
    bodyFormData.set( cropped ? 'image' : 'imageUncropped', file );
    if(side == 0) {
        url = "/webapp/document/verification/0/idcard-front/"
    } else {
        bodyFormData.set('documentRegion', documentRegion);
        url = "/webapp/document/verification/1/idcard-back/"
    }

    bodyFormData.set('submissionId', sessionStorage.getItem('submissionid'));

    return request({
        url: url,
        method: 'POST',
        data: bodyFormData
    })
}

/**
 * Get document classification to identify the type of document client is uploading.
 *
 * @param image
 * @returns {Promise<unknown>}
 */
function getDocumentClassification(image) {

    // Construct request body
    let body = new FormData();
    body.set('image', image);
    body.set('submissionId', sessionStorage.getItem('submissionid'))

    // Make API request and return a Promise
    return request({
        url: '/webapp/document/classification',
        method: 'POST',
        data: body
    });
}

/**
 * Get passive liveness result from the selfie image captured
 *
 * @param image
 * @returns {Promise<unknown>}
 */
function getPassiveLivenessResult(image) {

    // Construct request body
    let body = new FormData();
    body.set('image', image);
    body.set('submissionId', sessionStorage.getItem('submissionid'))

    // Make API request and return a Promise
    return request({
        url: '/webapp/facial/passive-liveness',
        method: 'POST',
        data: body
    });
}

/**
 * Get Submission State
 *
 */

function getSubmissionState() {
    // Construct request body
    let body = new FormData();
    body.set('source_type', ( isMobile || isTablet ) ? 'webapp-capture' : 'webapp-upload');

    //Make API request and return a Promise
    return request({
        url: '/webapp/state',
        method: 'POST',
        data: body
    });
}

/**
* Get Company Branding Colors and logo from the webapp-profile Api service
*
*/

function getAppProfile() {
    //Make API request and return a Promise
    return request({
        url: '/webapp/profile',
        method: 'GET'
    });
}

/**
 * Finalize the submission.
 *
 * @returns {Promise<unknown>}
 */
function postFinalizeSubmission() {

    // Construct request body
    let body = new FormData();

    body.set('submissionId', sessionStorage.getItem('submissionid'));

    // Make API request and return a Promise
    return request({
        url: '/webapp/document/finalize/',
        method: 'POST',
        data: body
    });
}


/**
 * Submit EVerificitation address for verification
 *
 * @returns {Promise<unknown>}
 * @param data
 */
function postEVerification(data) {

    // Construct request body
    let body = new FormData();
    body.set('submissionId', sessionStorage.getItem('submissionid'))
    body.set('firstName', data.firstName);
    body.set('lastName', data.lastName);
    body.set('dob', data.dob);
    body.set('city', data.city);
    body.set('country', data.country);
    body.set('address1', data.address1);
    body.set('address2', data.address2);
    body.set('postalCode', data.postCode);
    body.set('phone', data.phone);
    body.set('phonePrefix', data.phonePrefix);
    body.set('phoneRaw', data.phoneRaw);
    body.set('eVerificationCustomField', data.customField);
    body.set('uuid', sessionStorage.getItem('uuid'));

    // Set source type
    body.set('source_type', ( isMobile || isTablet ) ? 'webapp-capture' : 'webapp-upload');

    // Make API request and return a Promise
    return request({
        url: '/webapp/address/verification',
        method: 'POST',
        data: body
    });
}

/**
 * Submit address for storage
 *
 * @returns {Promise<unknown>}
 * @param data
 */

function postStoreAddress(data) {

    // Construct request body
    let body = new FormData();
    body.set('submissionId', sessionStorage.getItem('submissionid'))
    body.set('firstName', data.firstName);
    body.set('lastName', data.lastName);
    body.set('dob', data.dob);
    body.set('city', data.city);
    body.set('country', data.country);
    body.set('address1', data.address1);
    body.set('address2', data.address2);
    body.set('postalCode', data.postCode);
    body.set('phone', data.phone);
    body.set('phonePrefix', data.phonePrefix);
    body.set('phoneRaw', data.phoneRaw);
    body.set('eVerificationCustomField', data.customField);
    body.set('uuid', sessionStorage.getItem('uuid'));

    // Set source type
    body.set('source_type', ( isMobile || isTablet ) ? 'webapp-capture' : 'webapp-upload');

    // Make API request and return a Promise
    return request({
        url: '/webapp/address/store',
        method: 'POST',
        data: body
    });
}

/**
 * Get Countries for populating dropdowns
 *
 */

function getCountries() {
    //Make API request and return a Promise
    return request({
        url: '/webapp/countries',
        method: 'GET'
    });
}

/**
 * Perform Address Lookup
 *
 */

function lookupAddress(address, countryCode) {
    //Make API request and return a Promise
    return request({
        url: 'webapp/address/lookup',
        method: 'GET',
        params: {
            iso_country: countryCode,
            free_form_address: address
        }
    });
}

/**
 * Send SMS for Mobile Handoff
 */

function postSendHandoffSms(data) {

    // Construct request body
    let body = new FormData();
    body.set('phone', data.phone);
    body.set('uuid', data.uuid);
    body.set('submissionId', sessionStorage.getItem('submissionid'))

    //Make API request and return a Promise
    return request({
        url: '/webapp/handoff-sms',
        method: 'POST',
        data: body
    });
}


/**
 * Get Token for acuant sdk
 *
 */

function getToken() {
    //Make API request and return a Promise
    return request({
        url: '/acuant/token',
        method: 'GET'
    });
}


const ApiService = {
    getDocInstance,
    postImage,
    processDLDuplex,
    getDocumentClassification,
    getPassiveLivenessResult,
    getAppProfile,
    getCountries,
    postSendHandoffSms,
    postEVerification,
    postStoreAddress,
    postFinalizeSubmission,
    getSubmissionState,
    lookupAddress,
    getToken,

};

export default ApiService;