import ApiService from "../../services/api/api";
import {history, store} from "../../store";
import {initialiseEVerification} from "./eVerificationActions";
import {initialiseState} from "./submissionStateActions";
import {isMobile, isTablet} from "react-device-detect";

export function setInstanceID(payload) {
    return (dispatch) => {
        const uuid = sessionStorage.getItem("uuid");

        dispatch({
            type: "SHOW_HEADER",
            data: sessionStorage.getItem("disableheader") === 'true' ? false : true
        }, dispatch({
            type: "RESET_SPINNER"
        }), dispatch({
            type:"RESET_E_VERIFICATION"
        }));


        if (uuid !== null && uuid.length) {

            dispatch({type: "PENDING"});

            ApiService.getDocInstance().then(data => {

                dispatch({type: "COMPLETE"});

                if (data && data.hasOwnProperty('success')) {

                    // Could not retrieve session, UUID invalid
                    if (data.success === false ) {
                        return dispatch({
                            type: "SET_UUID_VALIDITY",
                            data: false
                        })
                    }

                    // add session id cookie from the api once we make contact
                    if (data.hasOwnProperty('sessionid')) {
                        sessionStorage.setItem('sessionid', data.sessionid);
                        dispatch({
                            type: "PENDING"
                        });

                        ApiService.getAppProfile().then(response => {

                            let data = response.data;
                            dispatch({ type: "COMPLETE" })

                            // Store crop settings
                            if (data.profile.crop_settings) {
                                dispatch({
                                    type: "SET_CROP_SETTINGS",
                                    data: data.profile.crop_settings,
                                    device: isMobile ? 'mobile' : 'desktop'
                                })
                            }

                            if (data.limitReached === true ) {

                                // Limit has been reached, display message, but still set branding
                                dispatch({
                                    type: "LIMIT_REACHED"
                                },
                                dispatch({
                                    type: "SET_UUID_VALIDITY",
                                    data: true
                                }));
                            } else {
                                dispatch({
                                    type: "SET_UUID_VALIDITY",
                                    data: true
                                })
                            }

                            // if e-verification is details are present in profile, initialise data
                            if (data.profile['e_verification_details']['enabled'] === true){
                                dispatch(initialiseEVerification(data.profile['e_verification_details']));
                            }

                            if (response.code === 200 && data && data.profile.hasOwnProperty('company_branding')) {

                                // Log info
                                console.debug('setBranding', data.profile);

                                dispatch({type: "PENDING"});

                                ApiService.getSubmissionState().then(state => {
                                    if (state.data && state.data.details.hasOwnProperty('submission_id')) {

                                        // Set Submission ID
                                        sessionStorage.setItem('submissionid', state.data.details.submission_id);
                                        dispatch(initialiseState(state.data));
                                    } else {
                                        dispatch({type: "COMPLETE"});
                                        return dispatch({
                                            type: "LIMIT_REACHED"
                                        });
                                    }
                                })

                                return dispatch({
                                    type: "@@acuant/SET_INSTANCE_ID",
                                    data: data.sessionid

                                }, dispatch({
                                    type: "@@acuant/SET_BRANDING",
                                    data: data.profile
                                }));

                            } else {
                                return dispatch({
                                    type: "SET_UUID_VALIDITY",
                                    data: false
                                });
                            }
                        }).catch(err => {
                            console.log(err);
                            throw new Error(err);
                        });

                    } else {
                        throw new Error();
                    }
                }
            }).catch(err => {
                console.log(err)
                throw new Error(err);
            });
        }
    };
}

export function resetConfig() {
    return {
        type: "@@acuant/RESET_CONFIG"
    }
}

export function submitFrontID() {
    return {
        type: '@@acuant/FRONT_ID_SUBMITTED'
    }
}

export function submitBackID() {
    return {
        type: '@@acuant/BACK_ID_SUBMITTED'
    }
}

export function acuantInitialized(bool) {
    return {
        type: 'ACUANT_INITIALIZED',
        data: bool
    }
}
