export function eVerificationReducer(state = {}, action) {
    switch (action.type) {

        case 'SET_COUNTRIES':
            return {
                ...state,
                countries: action.data.countries,
                selectedCountry: action.data.current_country
            }
        case 'SET_FORM_VALUE':
            return {
                ...state,
                [action.data.id] : action.data.value
            }
        case 'SET_E_VERIFICATION_CUSTOM_FIELDS':
            return {
                ...state,
                customFields: action.data
            }
        case 'SET_ACTIVATED_COUNTRIES':
            return {
                ...state,
                activatedCountries: action.data
            }
        case 'SET_SELECTED_COUNTRY':
            return {
                ...state,
                selectedCountry: action.data.selectedCountry,
            }
        case 'SET_ADDRESS_FROM_LOOKUP':
            return {
                ...state,
                address1: action.data.address1,
                address2: action.data.address2,
                city: action.data.locality,
                postCode: action.data.postalCode,
            }
        case 'ADDRESS_SUBMITTED' :
            return {
                ...state,
                addressSubmitted: true,
            }
        case 'SET_EVERIFICATION_ERRORS' :
            return {
                ...state,
                errors: action.data.errors,
            }
        case 'RESET_E_VERIFICATION':
            return {
                ...state,
                addressSubmitted: false,
                enabled: false,
                customFields: [],
                activatedCounties: [],
                countries: [],
                selectedCountry: {
                    name: "",
                    iso_3166_3: "",
                    iso_3166_2: "",
                    supports_address_lookup: false
                },
                firstName: null,
                lastName: null,
                dob: null,
                address1: "",
                address2: "",
                city: "",
                postCode: "",
                phone: null,
                customField: null,
                errors: {},
            }

        default:
            return state;
    }
}