import axios from 'axios'
import axiosRetry from 'axios-retry';
import { environment } from '../../config/environment';
/*
 global window
 */

const client = (() => {
    return axios.create({
        baseURL: environment.apiDomain()
    });
})();

axiosRetry(client, { retries: 3 });

const request2 = function(options, store) {    
    
    const onSuccess = function(response) {
        console.debug('Request Successful!', response);
        return response.data;
    };

    const onError = function(error) {
        return Promise.reject(error.response || error.message);
    };

    if (process.env.NODE_ENV === 'development') {
        let AUTH_TOKEN = btoa(`${process.env.REACT_APP_USER_NAME}:${process.env.REACT_APP_PASSWORD}`);
        options.headers = {
            "Authorization": `Basic ${AUTH_TOKEN}`,
            "uuid": `${sessionStorage.getItem("uuid")}`
            // "uuid": `${window.appData.uuid}`        
        };
    } else {
        options.headers = {
            "Authorization": `Basic ${process.env.REACT_APP_AUTH_TOKEN}`,
            "uuid": `${sessionStorage.getItem("uuid")}`
            // "uuid": `${window.appData.uuid}`       
        };
    }

    
    let seshId = sessionStorage.getItem("sessionid");
    // let seshId = window.appData.sessionid;

    if( typeof seshId == "string" ) {
        options.headers.sessionid = seshId;
    }

    return client(options)
        .then(onSuccess)
        .catch(onError);
};


export default request2;
