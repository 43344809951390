// React packages
import React, { Component, Fragment }                from 'react';
import { bindActionCreators }                        from 'redux';
import { connect }                                   from 'react-redux';
import { withNamespaces, NamespacesConsumer, Trans } from 'react-i18next';
import { environment }                               from '../config/environment';
import { isMobile }                                  from "react-device-detect";

// Other packages
import _ from 'lodash';
import i18next from "i18next";

// Actions
import { decrementSidesLeft, incrementSidesLeft, setCardOrientation, resetIDProperties } from './actions/idPropertiesActions';
import { setRetryLimit } from "./actions/submissionStateActions";
// Services
import imageValidatorService from '../services/shared/imageValidatorService';
import documentService       from '../services/shared/documentService';
import apiService            from '../services/api/api';

// Components
import Header     from './Header';
import Processing from './Processing';
import CustomButton from './Button';
import Navigator from "./Navigator";

class ProcessedImageResult extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDocumentRegion: 0,
            document: {},
            processing: false,
            validation: {
                messages: '',
                validated: false
            },
            classification: {
                messages: '',
                classified: false
            },
            rotationClass: '',
            failed: false,
            expired: false,
            navigation: {
                action: 'load',
                props: null
            }
        }
    }

    /**
     * React component life cycle hook.
     */ 
    componentDidMount() {

        // Define document being processed
        let document = documentService.findById(this.props.location.state.properties.documentId);

        // Set state properties
        this.setState({
            processing: false,
            document: document,
        });

        // TODO pass correctly formatted data
        // Define image properties required for validation
        let properties = {
            ...this.props.location.state.properties,
            originalImage: this.props.location.state.uncroppedImage,
            croppedImage: this.props.location.state.croppedImage,
            croppedImageWidth: this.props.location.state.width,
            croppedImageHeight: this.props.location.state.height,
            isPassport: this.props.location.state.properties.isPassport,
            document: document,
        }

        // Log outcome
        console.debug('Document analysis result from SDK', properties);

        // Back of ID does not need to be classified
        if ( this.props.orientation === 1 ){
            this.state.classification.classified = true;
            this.validateUploadedDocument(properties);
        } else {
            this.classifyUploadedDocument(properties);
        }
    }

    /**
     * Classify uploaded document.
     *
     * @param properties
     */
    classifyUploadedDocument(properties) {
        // Get document classification using cropped or uncropped image, try cropped first
        apiService.getDocumentClassification(
            documentService.urlToBlob(properties.croppedImage || this.getUncroppedImage())
        ).then(response => {
            let document = null;

            if (response.data) {
                // Set document based on classification
                document = documentService.findByClassification(response.data.classification);

                // Log outcome
                console.debug('Document classified.', document);
            } else {
                // Could not get classification, fall back to selected document
                document = documentService.findById(this.props.location.state.properties.documentId);

                console.debug('Document not classified, fallback to manually selected', document);
            }

            if (document) {
                this.state.classification.classified = true;
                properties.document = document;
                this.setState({
                    document: document,
                    currentDocumentRegion: response.data ? response.data.classification.documentRegion : this.state.currentDocumentRegion
                });
            } else {
                this.state.classification.messages = i18next.t('idpal_classification_error');
            }
        }).catch(error => {
            // Handle error
        }).finally(() => {
            // Only validate if classification is successful
            if (this.state.classification.classified) {
                // Set validation results
                this.validateUploadedDocument(properties);
            } else {
                this.setState({
                    processing: false,
                });
            }
        });
    }

    /**
     * Validating uploaded document.
     *
     * @param properties
     */
    validateUploadedDocument(properties) {

        // Set validation results
        imageValidatorService(properties).then((response) => {
            this.setState({
                validation: response,
                processing: false,
            });
        });
    }

    processVerification(response) {
        this.showSpinner();

        // Update the sessionId value in sessionStorage if the one is returned from the response
        if (response.data && response.data.hasOwnProperty('sessionid')) {
            sessionStorage.setItem('sessionid', response.data.sessionid);
        }

        // Handle documents which require two pages to be submitted
        if (this.state.document.pages.single === false) {

            // Set relevant states
            this.props.decrementSidesLeft();
            this.props.setCardOrientation(1);

            // Capture second document
            if (this.props.sidesLeft === 1) {
                sessionStorage.setItem('documentRegion', this.state.currentDocumentRegion);
                this.setState({
                    navigation: {
                        action: 'next',
                        props: {
                            documentId: this.state.document.id,
                            sidesLeft: this.props.sidesLeft
                        }
                    }
                });
            } else {

                // Handle UI transitions
                if (response.code === 200 && response.data.hasOwnProperty('verification')) {
                    if (response.data.verification.status !== "Passed"
                        && this.props.submissionAttempts > 1 ) {
                        this.setState({ expired: response.data.verification.expired })
                        this.setState({ failed: true });
                        this.hideSpinner();
                    } else {
                        this.documentCheckComplete();
                    }

                    // Clear document region once back image has been uploaded
                    sessionStorage.removeItem('documentRegion');
                } else if (response.code === 202) {
                    // Successful response, but no verification data, accept document and proceed.
                    this.documentCheckComplete();

                } else {
                    this.setState({
                        navigation: {
                            action: 'error'
                        }
                    });
                }
            }
        }

        // Handle documents which require only page to be submitted
        if (this.state.document.pages.single === true
            && this.state.document.pages.number === 1
        ) {

            // Set state
            this.props.decrementSidesLeft();

            // Handle UI transitions
            if (response.code === 200 && response.data.hasOwnProperty('verification')) {

                // Failed verification, recapture if attempts remaining
                if (response.data.verification.status !== "Passed"
                    && this.props.submissionAttempts > 1 ) {
                    this.setState({ expired: response.data.verification.expired })
                    this.setState({ failed: true });
                    this.hideSpinner();
                } else {
                    this.documentCheckComplete();
                }
            } else if (response.code === 202) {
                // Successful response, but no verification data, accept document and proceed.
                this.documentCheckComplete();


            } else {
                this.setState({
                    navigation: {
                        action: 'error'
                    }
                });
            }
        }
    }

    /**
     * Upload document to the API
     *
     * @param blobData
     * @param cropped
     */
    uploadDocument(blobData, cropped) {
        this.showSpinner();

        let submissionType;

        // Handle documents which require two pages to be submitted
        if (this.state.document.pages.single === false
            && this.state.document.pages.number === 2
        ) {
            submissionType = 'processDLDuplex';

        // Handle documents which require only page to be submitted
        } else if (this.state.document.pages.single === true
            && this.state.document.pages.number === 1
        ) {
            submissionType = 'postImage';
        }

        let documentRegion = sessionStorage.getItem('documentRegion');

        apiService[submissionType](
            this.props.instanceID,
            this.props.orientation,
            blobData,
            cropped,
            documentRegion
        ).then(response => {
            this.processVerification(response);
        }).catch(err => {
            this.hideSpinner();
            this.setState({
                navigation: {
                    action: 'error'
                }
            });
            throw new Error(err);
        });
    }

    /**
     * Proceed to next step of user flow.
     */
    proceedToNextStep() {
        let cropped = null;
        if ( this.state.document.name === "Passport" ) {
            cropped = this.props.cropSettings.passport
        } else if (this.state.document.name === "Drivers License") {
            cropped = this.props.cropSettings.id_card
        }

        let image = documentService.urlToBlob(cropped ?
            this.props.location.state.croppedImage : this.getUncroppedImage()
        );
        this.uploadDocument( image, cropped );
    }

    /**
     * Retry document upload.
     */
    retryDocumentUpload(thisSideOnly = false) {
        // Reset document validation items
        this.setState({
                classification: {
                    messages: '',
                    classified: false
                },
                validation: {
                    messages: '',
                    validated: false
                },
                expired: false,
                failed: false,
            }
        )

        if (!thisSideOnly) {
            this.props.resetIDProperties();
        }

        this.setState({
           navigation: {
               action: 'next',
               props: {
                   isRetry: true,
                   documentId: this.state.document.id,
                   sidesLeft: this.props.sidesLeft
               }
           }
        });
    }

    /**
     * Document check complete, continue to success or passive liveness
     */
    documentCheckComplete() {
        this.props.setRetryLimit(0);
        this.setState({
            navigation: {
                action: 'next'
            }
        })
    }

    /**
     * if uncropped image is too large in height, width, or filesize, use downscaled version
     */
    getUncroppedImage() {
        if (this.props.location.state.imageSize > environment.maxFileSize
            || this.props.location.state.originalHeight > environment.maxHeight
            || this.props.location.state.originalWidth > environment.maxWidth ) {
            return this.props.location.state.downscaledImage
        } else {
            return this.props.location.state.originalImage
        }
    }

    /**
     * Show spinner.
     */
    showSpinner() {
        this.setState({ processing: true });
    }

    /**
     * Hide spinner.
     */
    hideSpinner() {
        this.setState({ processing: false });
    }

    rotateImage() {
        let className = this.state.rotationClass === '' ? 'flip-image' : '';
        this.setState({ rotationClass: className })
    }

    /**
     * Render component.
     */
    render() {

        // Instantiate translation service
        const { t, i18n } = this.props;

        // Set props
        let data = this.props.location.state;
        let cardImage = this.props.location.state.croppedImage ?? this.props.location.state.uncroppedImage;

        // Render loader component (while state is processing)
        if (this.state.processing || (!this.state.validation.messages && !this.state.classification.messages)) {
            return (
                <Fragment>
                    <Processing document={data}/>
                    <Navigator page={'document_review'} action={this.state.navigation.action} propsToPass={this.state.navigation.props} />
                </Fragment>
                )
        }

        // Render component
        return (
            <Fragment>
                <Header />

                <div className='o-site-wrap'>

                    {/*Normal Submission Process*/}
                    { this.props.submissionAttempts > 1 && !this.state.failed &&
                    <Fragment>

                        {!this.state.classification.classified &&
                            <div className='u-generic-text  u-text-center'>
                            <p className={'description error'}>{this.state.classification.messages}</p>
                            </div>
                        }

                        {!this.state.validation.validated &&
                        <div className='u-generic-text  u-text-center'>
                        {/* <img alt='idscango'
                                     className='icon'
                                     src={require('../assets/images/icon_attention@2x.png')} /> */}
                            <p className={'description error'}>{this.state.validation.messages}</p>
                        </div>
                        }

                        <div className='u-generic-text  u-text-center'>
                            {this.state.validation.validated && this.state.classification.classified &&
                            <p className={'description error'}>{this.state.validation.messages}</p>
                            }
                        </div>

                        <div className='u-display-upload u-text-center'>
                            {cardImage &&
                                <img id="document-image"
                                alt={'idscango'}
                                src={cardImage}
                                className={'capture ' + this.state.rotationClass}/>
                            }
                        </div>

                        <div className="u-generic-text  u-text-center">
                            {this.state.validation.validated &&
                            <CustomButton className={'btn'} label = {t('idpal_continue_with_this_image')}
                            handleClick = {() => this.proceedToNextStep()} />
                            }

                            {this.state.validation.validated &&
                            <CustomButton type='customhover' className={'btn outline'} label = {t('idpal_rotate_this_image')}
                            handleClick = {() => this.rotateImage()} />
                            }

                            {(!data.orientation || !this.state.classification.classified) &&
                            <CustomButton type='customhover' className={'btn outline'} label = {t('idpal_retry')}
                            handleClick = {() => this.retryDocumentUpload(true)} />
                            }
                        </div>
                    </Fragment>
                    }

                    {/*Submitted ID Failed Verification*/}
                    { this.state.failed &&
                    <Fragment>
                        { this.state.expired === false &&
                        <div className='u-generic-text  u-text-center'>
                            <p className={'description error'}>{t('idpal_id_verification_failed')}</p>
                        </div>
                        }

                        { this.state.expired === true &&
                        <div className='u-generic-text  u-text-center'>
                            <p className={'description error'}>{t('idpal_id_verification_expired')}</p>
                        </div>
                        }

                        <div className='u-display-upload u-text-center'>
                            {cardImage &&
                            <img id="document-image"
                                 alt={'idscango'}
                                 src={cardImage}
                                 className={'capture ' + this.state.rotationClass}/>
                            }
                        </div>

                        <CustomButton type='customhover' className={'btn outline'} label = {t('idpal_retry')}
                              handleClick = {() => this.retryDocumentUpload()} />

                    </Fragment>
                    }

                    {/* Out of retry attempts. Submit whatever regardless of validation or classification */}
                    {this.props.submissionAttempts <= 1 &&
                    <Fragment>
                        <div className='u-generic-text  u-text-center'>
                            <p className={'description error'}>{t('idpal_ensure_all_text_is_visible')}</p>
                        </div>

                        <div className='u-display-upload u-text-center'>
                            {cardImage &&
                            <img id="document-image"
                                 alt={'idscango'}
                                 src={cardImage}
                                 className={'capture ' + this.state.rotationClass}/>
                            }
                        </div>

                        <CustomButton className={'btn'} label = {t('idpal_continue_with_this_image')}
                        handleClick = {() => this.proceedToNextStep()} />

                        <CustomButton type='customhover' className={'btn outline'} label = {t('idpal_rotate_this_image')}
                        handleClick = {() => this.rotateImage()} />

                    </Fragment>
                    }


                    {/* Display captured image info on testing envs */}
                    <div className="u-generic-text  u-text-center">
                        {environment.debugMode == 'true' &&
                            <div>
                                <p>DPI: {data.properties.dpi}</p>
                                <p>Glare: {data.properties.glare}</p>
                                <p>Blur/Sharpness: {data.properties.sharpness}</p>
                            </div>
                        }
                    </div>

                </div>

                <Navigator page={'document_review'} action={this.state.navigation.action} propsToPass={this.state.navigation.props} />

            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        orientation: state.idProperties.orientation,
        cardType: state.idProperties.cardType,
        sidesLeft: state.idProperties.sidesLeft,
        frontSubmitted: state.config.frontSubmitted,
        backSubmitted: state.config.backSubmitted,
        submissionAttempts: state.submissionState.submissionAttempts,
        screens: state.submissionState.screens,
        cropSettings: state.config.cropSettings
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        decrementSidesLeft,
        incrementSidesLeft,
        resetIDProperties,
        setCardOrientation,
        setRetryLimit,
    }, dispatch);
}

export default withNamespaces('translation')(connect(mapStateToProps, mapDispatchToProps)(ProcessedImageResult));
