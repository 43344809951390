import ApiService from "../../services/api/api";
import { history } from "../../store";
import i18next from "i18next";
import { isMobile, isTablet } from "react-device-detect";

export function initialiseEVerification(data) {
    return (dispatch) => {
        dispatch({
            type:"RESET_E_VERIFICATION"
        });

        dispatch({
            type: "ADDRESS_LOOKUP_ACTIVE",
            data: data['address_lookup']
        }, dispatch ({
            type: "RESET_ADDRESS_LOOKUP"
        }))

        let customFields = data['custom_fields'];
        let customFieldArray = [];

        let activatedCountries = data['activated_countries'];

        // Build custom field rules
        for (let i = 0; i < customFields.length; i++)  {
            let field = {
                name: customFields[i].field_name,
                id: "customField",
                min : customFields[i].min_length,
                max : customFields[i].max_length,
                regex : customFields[i].regex,
                required : customFields[i].is_required,
                iso : customFields[i].iso_3166_3,
                countryName : customFields[i].country_name,
                messages: {
                    regex: i18next.t("idpal_invalid_regex_error", {field: customFields[i].field_name})
                }
            };
            customFieldArray.push(field);
        }
        dispatch({
            type: "SET_E_VERIFICATION_CUSTOM_FIELDS",
            data: customFieldArray
        }, dispatch({
            type: "SET_ACTIVATED_COUNTRIES",
            data: activatedCountries
        }))
    };
}

export function submitEVerification(data) {
    return (dispatch) => {

        dispatch({ type : "PENDING" });

        ApiService.postEVerification(data).then(response => {

            dispatch({ type: "COMPLETE" });

            // Retrieve response data
            let addressVerification = response.data.addressVerification;
            if (addressVerification.hasOwnProperty('result')) {

                // Save result to submissionState
                dispatch({
                    type: "SET_E_VERIFICATION_RESULT",
                    data: addressVerification.result
                })
            } else {
                // Save error to submissionState
                dispatch({
                    type: "SET_E_VERIFICATION_RESULT",
                    data: 'error'
                })
            }
        });
    }
}
export function storeAddress(data) {
    return (dispatch) => {
        dispatch({
            type : "PENDING"
        });
        ApiService.postStoreAddress(data).then(response => {
            dispatch({
                type: "COMPLETE"
            });

            // Save result to submissionState
            dispatch({
                type: "SET_E_VERIFICATION_RESULT",
                data: 'stored'
            })
        })
    }
}

export function getCountries() {
    return (dispatch) => {
        dispatch({
            type: "PENDING"
        });
        ApiService.getCountries().then(response => {
            dispatch({
                type: "COMPLETE"
            });

            if (response.code === 200 ) {
                dispatch({
                    type: "SET_COUNTRIES",
                    data: response.data.countries,
                })
            }

        });
    }
}

export function setPhone(value, data, event, formattedValue) {
    if (data.dialCode) {
        return (dispatch) => {
            dispatch({
                    type: "SET_FORM_VALUE",
                    data: {
                        id: "phone",
                        value: value
                    }
                },
                dispatch({
                        type: "SET_FORM_VALUE",
                        data: {
                            id: "phonePrefix",
                            value: data.dialCode
                        }
                    },
                    dispatch({
                        type: "SET_FORM_VALUE",
                        data: {
                            id: "phoneRaw",
                            value: value.slice(data.dialCode.length)
                        }
                    })
                )
            )
        }
    } else {
        return (dispatch) => {
            dispatch({
                type: "SET_FORM_VALUE",
                data: {
                    id: "phone",
                    value: null
                }
            })
        }
    }
}

export function setFormValue(event) {
    return (dispatch) => {
        dispatch({
            type: "SET_FORM_VALUE",
            data: {
                id: event.target.id,
                value: event.target.value
            }
        })
    }
}

export function setSelectedCountry(code, countries) {

    let selectedCountry = countries.find(o => o.iso_3166_3 === code);
    return (dispatch) => {
        dispatch({
            type: "SET_SELECTED_COUNTRY",
            data: {
                selectedCountry: selectedCountry,
            }
        });
    }
}

export function setErrors(errors) {
    return (dispatch) => {
        dispatch({
            type: "SET_EVERIFICATION_ERRORS",
            data: {
                errors: errors,
            }
        });
    }
}
